<ion-content [fullscreen]="true">
  <div class="center-content">
    <ion-progress-bar type="indeterminate" *ngIf="loading"></ion-progress-bar>
    <div class="center-header-item">
      <ion-label>
        <h1>Forgot Password</h1>
        <p class="ion-text-wrap">
          Enter your email and we'll send you a link to reset your password.
        </p>
      </ion-label>
    </div>

    <form [formGroup]="forgotPasswordForm" (ngSubmit)="onForgotPasswordClick()">
      <ion-item>
        <ion-input
          formControlName="email"
          label="Email"
          labelPlacement="stacked"
          type="email"
          placeholder="email@domain.com"
        ></ion-input>
      </ion-item>

      <ion-text
        color="danger"
        *ngIf="forgotPasswordForm.get('email')?.hasError('required') && forgotPasswordForm.get('email')?.touched"
      >
        Email is required.
      </ion-text>

      <ion-button type="submit" expand="full">Submit</ion-button>
    </form>

    <div routerLink="/login" class="login">Back to Login</div>
  </div>
</ion-content>
