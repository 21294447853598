<ion-content [fullscreen]="true">
  <div *ngIf="chats === null || chats?.length === 0" lines="none" class="empty-chats-div">
    <ion-icon name="mail-outline" class="empty-mail-icon"></ion-icon>

    <ion-label class="empty-chats-label">
      <h2>You have no chats. Start a conversation!.</h2>
    </ion-label>
  </div>

  <ion-list lines="inset" *ngFor="let chat of chats">
    <ion-item-sliding>
      @if(chat.id) {
      <ion-item
        class="mr-[5%]"
        [button]="true"
        (click)="navigateToChat(chat.id)"
        routerLink="/chat"
      >
        <ion-icon
          *ngIf="!chat.myConversationUser.isRead"
          name="ellipse"
          aria-hidden="true"
          slot="start"
          size="small"
          color="secondary"
        ></ion-icon>

        <ion-label>
          <div class="name-time">
            <h2 class="overflow-hidden whitespace-nowrap text-ellipsis">
              {{ chat.conversationName }}
            </h2>
            <h3 class="flex justify-end">
              {{chat.latestMessage.createdDate | date:'short'}}
            </h3>
          </div>

          <p class="overflow-hidden whitespace-nowrap text-ellipsis">
            {{chat.latestMessage.body}}
          </p>
        </ion-label>
      </ion-item>
      }
      <ion-item-options slot="end">
        <ion-item-option
          color="danger"
          expandable="true"
          slot="end"
          [id]="'open-my-chats-sheet-' + chat.id"
          (click)="presentActionSheet(chat.myConversationUser)"
        >
          <ion-icon slot="icon-only" name="trash"></ion-icon>
        </ion-item-option>
      </ion-item-options>
    </ion-item-sliding>
  </ion-list>

  <ion-fab slot="fixed" class="top" vertical="bottom" horizontal="end">
    <ion-fab-button
      expand="block"
      (click)="openSearch()"
      id="present-alert"
    >
      <ion-icon size="large" name="add-outline"></ion-icon>
    </ion-fab-button>
  </ion-fab>
</ion-content>
