import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { environment } from 'src/environments/environment';

export const EduEmailValidator: ValidatorFn = (
  control: AbstractControl,
): ValidationErrors | null => {
  const value = control.value || '';

  if (value && !value.includes('.edu')) {
    if (!environment.useEduValidator) {
      return { noEduEmail: false };
    } else {
      return { noEduEmail: true };
    }
  }

  return null;
};
