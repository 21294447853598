import { Injectable } from '@angular/core';
import { Comment, Post } from '../models/post.model';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { PickedFile } from '@capawesome/capacitor-file-picker';

@Injectable({
  providedIn: 'root',
})
export class CommentService {
  path = '/api/Comment';
  fileUploadPath = '/api/Storage/';

  constructor(private http: HttpClient) {}

  create(comment: Comment): Observable<Comment> {
    return this.http.post<Comment>(`${environment.API}${this.path}`, comment);
  }

  deleteComment(commentId: string) {
    return this.http.delete(`${environment.API}${this.path}/${commentId}`);
  }

  uploadCommentFile(commentId: string, file: File) {
    const formData = new FormData();

    formData.append(file.name, file);
    formData.append('commentId', commentId);

    return this.http.post<Comment>(
      `${environment.API}${this.fileUploadPath}Comment`,
      formData,
    );
  }

  deleteCommentFile(commentId: string) {
    return this.http.delete<Comment>(
      `${environment.API}${this.fileUploadPath}Comment/${commentId}`,
    );
  }

  report(id: string, reason: string) {
    return this.http.post(`${environment.API}${this.path}/Report`, {
      id,
      reason,
    });
  }
}
