<ion-content [fullscreen]="true">
  <ion-progress-bar type="indeterminate" *ngIf="loading"></ion-progress-bar>
  <form [formGroup]="updatePasswordForm" (ngSubmit)="onSave()">
    <ion-list>
      <ion-item>
        <ion-input
          formControlName="currentPassword"
          label="Current Password"
          labelPlacement="stacked"
          type="password"
          fill="solid"
        >
          <ion-input-password-toggle slot="end"></ion-input-password-toggle>
        </ion-input>
      </ion-item>

      <ion-text
        color="danger"
        *ngIf="updatePasswordForm.get('currentPassword')?.hasError('required') && updatePasswordForm.get('currentPassword')?.touched"
        class="requiredText"
      >
        Current password is required.
      </ion-text>

      <ion-item>
        <ion-input
          formControlName="newPassword"
          label="New Password"
          labelPlacement="stacked"
          type="password"
          fill="solid"
        >
          <ion-input-password-toggle slot="end"></ion-input-password-toggle>
        </ion-input>
      </ion-item>

      <ion-text
        color="danger"
        *ngIf="updatePasswordForm.get('newPassword')?.hasError('required') && updatePasswordForm.get('newPassword')?.touched"
        class="requiredText"
      >
        A new password is required.
      </ion-text>

      <ion-item>
        <ion-input
          formControlName="confirmNewPassword"
          label="Confirm New Password"
          labelPlacement="stacked"
          type="password"
          fill="solid"
        >
          <ion-input-password-toggle slot="end"></ion-input-password-toggle>
        </ion-input>
      </ion-item>

      <ion-text
        color="danger"
        *ngIf="updatePasswordForm.get('confirmNewPassword')?.hasError('required') && updatePasswordForm.get('confirmNewPassword')?.touched"
        class="requiredText"
      >
        Confirming your password is required.
      </ion-text>

      <ion-text
        color="danger"
        *ngIf="this.updatePasswordForm.get('newPassword')?.value !== this.updatePasswordForm.get('confirmNewPassword')?.value"
        class="requiredText"
      >
        Passwords must match.
      </ion-text>
    </ion-list>

    <div class="buttons">
      <ion-button
        type="submit"
        color="success"
        [disabled]="updatePasswordForm.invalid"
      >
        <ion-label> Save </ion-label>
      </ion-button>

      <ion-button color="danger" id="present-alert-update-password">
        <ion-label> Cancel </ion-label>
      </ion-button>

      <ion-alert
        trigger="present-alert-update-password"
        header="Are you sure you want to cancel?"
        message="Any unsaved changes will be lost."
        [buttons]="alertButtons"
      ></ion-alert>
    </div>
  </form>
</ion-content>
