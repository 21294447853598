import { Component } from '@angular/core';
import { addIcons } from 'ionicons';
import { CommonModule } from '@angular/common';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonItem,
  IonInput,
  IonButton,
  IonModal,
  IonButtons,
  IonIcon,
  IonTextarea,
  IonLabel,
  IonList,
  IonCard,
  IonRadioGroup,
  IonRadio,
  IonProgressBar,
  IonActionSheet,
  IonAlert,
  IonRefresher,
  IonRefresherContent,
  IonFooter,
  ModalController,
  IonFab,
  IonFabButton,
} from '@ionic/angular/standalone';
import {
  alertCircleOutline,
  closeOutline,
  sendOutline,
  trash,
  trashOutline,
  ellipsisHorizontalCircleOutline,
  flagOutline,
  mailOutline,
  thumbsUpOutline,
  thumbsUp,
  ellipsisHorizontalOutline,
  chatboxOutline,
  imageOutline,
  videocamOutline,
} from 'ionicons/icons';
import { Post, Comment } from 'src/app/models/post.model';
import { Router, RouterModule } from '@angular/router';
import { ToastService } from 'src/app/services/toast.service';
import { ReportModalComponent } from 'src/app/modals/report-modal/report-modal.component';
import { FilePicker, PickedFile } from '@capawesome/capacitor-file-picker';
import { UserProfile } from 'src/app/models/user-profile.model';
import { MeService } from 'src/app/services/me.service';
import { LoadingService } from 'src/app/services/loading.service';
import { PostService } from 'src/app/services/post.service';
import { Subscription } from 'rxjs';
import { AppService } from 'src/app/services/app.service';
import { CommentService } from 'src/app/services/comment.service';
import { LikesService } from 'src/app/services/likes.service';
import { Likes } from 'src/app/models/likes';
import { defaultProfilePicURL } from 'src/app/constants';

interface CreatePostForm {
  body: FormControl<string | null>;
}

@Component({
  selector: 'app-daily-chat',
  templateUrl: './daily-chat.page.html',
  styleUrls: ['./daily-chat.page.scss'],
  standalone: true,
  imports: [
    IonFooter,
    IonRefresherContent,
    IonRefresher,
    IonAlert,
    IonActionSheet,
    IonProgressBar,
    IonRadio,
    IonRadioGroup,
    IonCard,
    IonList,
    IonLabel,
    IonTextarea,
    IonIcon,
    IonButtons,
    IonModal,
    IonButton,
    IonInput,
    IonItem,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    IonFab,
    IonFabButton,
  ],
})
export class DailyChatPage {
  loadingBar: boolean = false;
  isUpdating: boolean = false;

  user?: UserProfile;
  queryParams = new Map<string, any>();
  isOptionsSheetOpen: boolean = false;
  itemId: string | undefined;
  isComment: boolean | undefined;

  isCommentSectionOpen: boolean = false;
  commentSectionPostId: string = '';

  postImg?: File;
  postVid?: PickedFile;

  commentImg?: File;
  commentVid?: PickedFile;

  postMediaURL?: string;
  commentMediaURL?: string;

  backSubscription?: Subscription;

  myLikedPosts: Likes[] = [];
  myLikesPostIds: string[] = [];

  defaultProfilePicURL = defaultProfilePicURL;

  public actionSheetButtons = [
    {
      text: 'Report',
      handler: () => {
        this.openReportModal();
      },
    },
    {
      text: 'Cancel',
    },
  ];

  posts: Post[] = [];

  createPostForm = new FormGroup<CreatePostForm>({
    body: new FormControl(null, [Validators.required]),
  });

  postCommentText = new FormControl<string | null>(null, [Validators.required]);

  constructor(
    private toastService: ToastService,
    private modalController: ModalController,
    private meService: MeService,
    private loading: LoadingService,
    private postService: PostService,
    private router: Router,
    private appService: AppService,
    private commentService: CommentService,
    private likesService: LikesService,
  ) {
    addIcons({
      mailOutline,
      ellipsisHorizontalOutline,
      trash,
      thumbsUpOutline,
      thumbsUp,
      chatboxOutline,
      sendOutline,
      imageOutline,
      trashOutline,
      videocamOutline,
      closeOutline,
      flagOutline,
      alertCircleOutline,
      ellipsisHorizontalCircleOutline,
    });
  }

  ionViewWillEnter(): void {
    this.meService.getUserProfile().subscribe({
      next: (response) => {
        this.user = response;
        this.queryParams.set('CampusId', response.campusId);
      },
      error: () => {
        this.toastService.presentToast(
          'An error has occurred. Please try again.',
        );
      },
    });

    this.listenForPosts();
    this.getLatestPosts();

    if (!this.backSubscription) {
      this.backSubscription = this.appService.backSubject.subscribe((_) => {
        this.router.navigateByUrl('/home');
      });
    }
  }

  ionViewDidLeave(): void {
    if (this.backSubscription) {
      this.backSubscription.unsubscribe();
      this.backSubscription = undefined;
    }

    this.postService.disconnect();
  }

  handleRefresh(event: any) {
    setTimeout(() => {
      //GET all posts
      event.target.complete();
    }, 2000);
  }

  listenForPosts() {
    this.postService.postsSubject.subscribe({
      next: () => {
        this.getLatestPosts();
      },
      error: () => {},
    });

    this.postService.postDeletedSubject.subscribe({
      next: () => {
        this.getLatestPosts();
      },
      error: () => {},
    });

    this.postService.updatedLikesSubject.subscribe({
      next: () => {
        this.getLatestPosts();
      },
      error: () => {},
    });

    this.postService.errorSubject.subscribe((err) => {});

    this.postService.connect();
  }

  getLatestPosts() {
    this.loadingBar = true;
    this.queryParams.set('CampusId', this.user?.campusId);
    this.postService.getPosts().subscribe({
      next: (posts) => {
        var queryParamsForLikes = new Map<string, any>();
        queryParamsForLikes.set('UserId', this.user?.userId);
        this.likesService.getLikesForUser(queryParamsForLikes).subscribe({
          next: (response) => {
            this.myLikedPosts = response;
            this.myLikesPostIds = response.map((obj) => obj.postId);
          },
        });
        this.loadingBar = false;
        this.posts = posts;
      },
      error: () => {
        this.loadingBar = false;
      },
    });
  }

  createPost() {
    if (
      this.createPostForm.controls.body.hasError('required') &&
      !this.postImg &&
      !this.postVid
    ) {
      this.toastService.presentToast('Please enter a message to send!');
      return;
    }

    this.loading.showLoading('Creating post please wait...');

    var post: Post = {
      body: this.createPostForm.controls.body.value ?? '',
      status: 'ACTIVE',
    };

    this.postService.createPost(post).subscribe({
      next: (post) => {
        if (this.postImg || this.postVid) {
          const file = this.postImg || this.postVid;
          this.loading.showLoading('Uploading File to the server...');

          this.postService.uploadPostFile(post.id!, this.postImg!).subscribe({
            next: (_) => {
              this.postService.webSocketConnectionActions('newPost');
              this.toastService.presentToast('Post created.');
              this.createPostForm.controls.body.reset();
              this.loading.hideLoading();

              this.postImg = undefined;
              this.postMediaURL = undefined;
              this.postVid = undefined;
            },
            error: (err: any) => {},
          });
        } else {
          this.postService.webSocketConnectionActions('newPost');
          this.toastService.presentToast('Post created.');
          this.createPostForm.controls.body.reset();
          this.loading.hideLoading();
        }
      },
      error: (response) => {
        this.toastService.presentToast('An error has occurred.');
        this.loading.hideLoading();
      },
    });

    this.loading.hideLoading();
  }

  deleteMyPost(id: string | undefined) {
    if (id) {
      this.loading.showLoading('Deleting Your Post...');

      this.postService.deletePost(id).subscribe({
        next: (_) => {
          this.likesService.deleteLikesByPostId(id).subscribe();
          this.toastService.presentToast('Your post was deleted.');
          this.loading.hideLoading();
          this.postService.webSocketConnectionActions('deletePost');
        },
        error: (err) => {
          this.toastService.presentToast(
            'There was an error deleting your Post.',
          );

          console.error(err);
          this.loading.hideLoading();
        },
      });
    }
  }

  deleteMyComment(id: string | undefined) {
    if (id) {
      this.loading.showLoading('Deleting Your Comment...');

      this.commentService.deleteComment(id).subscribe({
        next: (_) => {
          this.postService.webSocketConnectionActions('deletePost');
          this.toastService.presentToast('Your Comment was deleted.');

          this.loading.hideLoading();
        },
        error: (err) => {
          this.toastService.presentToast(
            'There was an error deleting your Comment.',
          );

          console.error(err);
          this.loading.hideLoading();
        },
      });
    }
  }

  async openReportModal() {
    const modal = await this.modalController.create({
      component: ReportModalComponent,
      componentProps: {
        id: this.itemId,
        isComment: this.isComment,
      },
    });
    modal.present();
  }

  setOptionsOpen(isOpen: boolean, id?: string, comment?: boolean) {
    this.isOptionsSheetOpen = isOpen;
    this.itemId = id;
    this.isComment = comment;
  }

  newLike(post: Post) {
    if (post.likesData?.userLikedPost) {
      this.likesService.delete(post.likesData.id).subscribe({
        next: (response) => {
          if (post.likesData) {
            post.likesData.userLikedPost = false;
            post.likesData.count--; 
          }
        },
      });
    } else {
      this.likesService.create(this.user?.userId!, post.id!).subscribe({
        next: (response) => {
          if (post.likesData) {
            post.likesData.id = response.id;
            post.likesData.userLikedPost = true;
            post.likesData.count++; 
          }
        },
      });
    }
  }

  showCommentSection(postId: string) {
    this.postCommentText.reset();
    this.isCommentSectionOpen = !this.isCommentSectionOpen;

    if (this.commentSectionPostId != postId) {
      this.commentSectionPostId = postId;
      this.isCommentSectionOpen = true;
    }
  }

  createComment(postId: string) {
    if (
      this.postCommentText.hasError('required') &&
      !this.commentImg &&
      !this.commentImg
    ) {
      this.toastService.presentToast('Please enter a comment to send!');
      return;
    }

    this.loading.showLoading('Creating comment...');

    const newComment: Comment = {
      body: this.postCommentText.value ?? '',
      status: 'ACITVE',
      postId: postId,
    };

    this.commentService.create(newComment).subscribe({
      next: (comment) => {
        if (this.commentImg || this.commentVid) {
          const file = this.commentImg || this.commentVid;
          this.loading.showLoading('Uploading File to the server...');

          this.commentService
            .uploadCommentFile(comment.id!, this.commentImg!)
            .subscribe({
              next: (_) => {
                this.postService.webSocketConnectionActions('newPost');
                this.toastService.presentToast('Comment created.');
                this.postCommentText.reset();

                this.commentImg = undefined;
                this.commentMediaURL = undefined;
                this.commentVid = undefined;

                this.loading.hideLoading();
              },
              error: (err: any) => {
                this.loading.hideLoading();
              },
            });
        } else {
          this.postService.webSocketConnectionActions('newPost');
          this.toastService.presentToast('Comment created.');
          this.postCommentText.reset();

          this.loading.hideLoading();
        }
      },
      error: (response: any) => {
        this.toastService.presentToast('An error has occurred.');
        this.loading.hideLoading();
      },
    });
  }
  async pickMedia(isPost: boolean, event: any) {
    const reader = new FileReader();
    const newFile: File = event.target.files[0];

    if (isPost) {
      this.postImg = newFile;
      reader.onload = (e: any) => {
        this.postMediaURL = e.target.result;
      };
      reader.readAsDataURL(this.postImg);
    } else {
      this.commentImg = newFile;
      reader.onload = (e: any) => {
        this.commentMediaURL = e.target.result;
      };
      reader.readAsDataURL(this.commentImg);
    }
  }

  clearMedia(post: boolean) {
    if (post) {
      this.postVid = this.postImg = undefined;
    } else {
      this.commentVid = this.commentImg = undefined;
    }
  }

  getCreateDate(date: string | Date): string {
    let newDate: Date = new Date();

    if (typeof date == 'string') {
      newDate = new Date(date);
    } else {
      newDate = <Date>date;
    }

    return newDate.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
    });
  }
}
