import { Component, OnInit } from '@angular/core';
import {
  IonApp,
  IonRouterOutlet,
  IonContent,
  IonFab,
  IonFabButton,
  IonAlert,
  IonHeader,
} from '@ionic/angular/standalone';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';

import {
  IonToolbar,
  IonButton,
  IonButtons,
  IonIcon,
  IonTitle,
  IonBackButton,
  IonFooter,
  IonBadge,
} from '@ionic/angular/standalone';

import { addIcons } from 'ionicons';
import {
  menuOutline,
  heartOutline,
  chatbubbleEllipsesOutline,
  arrowUndo,
  addOutline,
  homeOutline,
} from 'ionicons/icons';
import { Router, RouterModule, RoutesRecognized } from '@angular/router';
import { TokenService } from './services/token.service';
import { AppService } from './services/app.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  standalone: true,
  imports: [
    IonHeader,
    IonAlert,
    IonFabButton,
    IonFab,
    IonContent,
    IonApp,
    IonRouterOutlet,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    IonBadge,
    IonToolbar,
    IonButton,
    IonButtons,
    IonIcon,
    IonTitle,
    IonBackButton,
    IonContent,
    IonFooter,
    RouterModule,
  ],
})
export class AppComponent implements OnInit {
  pageTitle = '';
  notifications = [];

  get showFooterIcons() {
    return (
      (this.tokenService.authToken != undefined &&
      this.tokenService.authToken.trim() != '') &&
      !this.router.url.includes('verification')
    );
  }

  get showBack(): boolean {
    return this.appService.backSubject.observed;
  }

  constructor(
    private router: Router,
    private tokenService: TokenService,
    private appService: AppService,
  ) {
    addIcons({
      arrowUndo,
      homeOutline,
      heartOutline,
      chatbubbleEllipsesOutline,
      addOutline,
      menuOutline,
    });
  }

  ngOnInit() {
    this.appService.pageTitle.subscribe((data) => {
      this.pageTitle = data;
    });

    this.router.events.subscribe((data) => {
      if (data instanceof RoutesRecognized) {
        const routeData = data.state.root.firstChild?.data;

        if (routeData) {
          this.pageTitle = routeData['pageTitle'] ?? this.pageTitle;
        }
      }
    });
  }

  onBackClicked() {
    this.appService.backSubject.next(true);
  }
}
