<ion-content [fullscreen]="true">
  <div *ngIf="deals.length === 0" lines="none" class="empty-deals-div">
    <ion-icon name="pricetag-outline" class="no-deals-icon"></ion-icon>
    <ion-label class="no-deals-label">
      <h2>There are currently no local deals.</h2>
    </ion-label>
  </div>
  <ion-card
    *ngFor="let item of deals"
    class="flex justify-between items-center"
    (click)="openViewDealModal(item)"
  >
    <ion-card-header class="w-3/5">
      <ion-card-title>{{item.title}}</ion-card-title>
      <ion-card-subtitle>{{item.description}}</ion-card-subtitle>
    </ion-card-header>

    <img class="max-h-14 mr-3" [src]="item.image?.[0]?.url" />
  </ion-card>
</ion-content>
