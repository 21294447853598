import { Component, CUSTOM_ELEMENTS_SCHEMA, OnInit } from '@angular/core';
import {
  IonContent,
  IonItem,
  IonList,
  IonTitle,
  IonToolbar,
  ModalController,
} from '@ionic/angular/standalone';
import { Deal } from 'src/app/models/deal.model';
import { register } from 'swiper/element/bundle';

@Component({
  selector: 'app-view-deal-modal',
  templateUrl: './view-deal-modal.component.html',
  styleUrls: ['./view-deal-modal.component.scss'],
  standalone: true,
  imports: [IonContent, IonToolbar, IonTitle, IonList, IonItem],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ViewDealModalComponent implements OnInit {
  deal!: Deal;

  constructor(private modalController: ModalController) {
    register();
  }

  ngOnInit() {}

  cancel() {
    return this.modalController.dismiss(null, 'cancel');
  }
}
