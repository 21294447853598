<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-button color="light" (click)="cancel()">Cancel</ion-button>
    </ion-buttons>

    <ion-title color="light" class="text-center">Edit Listing</ion-title>

    <ion-buttons slot="end">
      <ion-button color="success" (click)="canDismiss()" [strong]="true"
        ><ion-icon size="large" name="checkmark-sharp"></ion-icon>
      </ion-button>

      <ion-button color="danger" (click)="presentConfirm()">
        <ion-label
          ><ion-icon size="large" name="trash-outline"></ion-icon
        ></ion-label>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <form [formGroup]="editItemForm">
    <ion-item>
      <ion-input
        formControlName="name"
        labelPlacement="stacked"
        label="Name"
      ></ion-input>
      <ion-text
        color="danger"
        *ngIf="
          editItemForm.get('name')?.hasError('required') &&
          editItemForm.get('name')?.touched
        "
      >
        A name is required.
      </ion-text>
    </ion-item>
    <ion-item>
      <ion-input
        type="number"
        formControlName="price"
        labelPlacement="stacked"
        label="Price"
      ></ion-input>
      <ion-text
        color="danger"
        *ngIf="
          editItemForm.get('price')?.hasError('required') &&
          editItemForm.get('price')?.touched
        "
      >
        A price is required.
      </ion-text>
    </ion-item>
    <ion-item>
      <ion-input
        formControlName="description"
        labelPlacement="stacked"
        label="Description"
      ></ion-input>
      <ion-text
        color="danger"
        *ngIf="
          editItemForm.get('description')?.hasError('required') &&
          editItemForm.get('description')?.touched
        "
      >
        A description is required.
      </ion-text>
    </ion-item>
    <ion-item>
      <ion-select
        interface="popover"
        label="Category"
        placeholder="Category"
        formControlName="categoryId"
      >
        <ion-select-option
          *ngFor="let category of categories"
          [value]="category.id"
        >
          {{ category.name }}
        </ion-select-option>
      </ion-select>
      <ion-text
        color="danger"
        *ngIf="
          editItemForm.get('category')?.hasError('required') &&
          editItemForm.get('category')?.touched
        "
      >
        A category is required.
      </ion-text>
    </ion-item>

    <input
      type="file"
      class="file-input"
      (change)="onFileSelected($event)"
      #fileUpload
      multiple
    />

    <div class="flex justify-center mt-2">
      <ion-button color="primary" (click)="fileUpload.click()">
        <ion-label color="light">Upload</ion-label>
      </ion-button>
    </div>
    <ion-grid>
      <ion-row>
        @if (updatedProduct.images && updatedProduct.images.length > 0) {
          <ion-col
            class="flex items-start justify-center"
            *ngFor="let img of updatedProduct.images"
            size="6"
          >
            <ion-card class="w-fit">
              <div class="w-full flex justify-center relative">
                <img class="flex min-h-12 max-h-28" src="{{ img.url }}" />
                <div class="absolute top-0 right-0">
                  <ion-button
                    (click)="deleteImg(img)"
                    size="small"
                    shape="round"
                    fill="clear"
                    ><ion-icon
                      color="danger"
                      size="large"
                      slot="icon-only"
                      name="close-circle-outline"
                    ></ion-icon
                  ></ion-button>
                </div>
              </div>
            </ion-card>
          </ion-col>
        }
        <ion-col
          class="flex items-start justify-center"
          *ngFor="let img of imgUrls"
          size="6"
        >
          <ion-card class="w-fit">
            <div class="w-full flex justify-center relative">
              <img class="flex min-h-12 max-h-28" src="{{ img }}" />
              <div class="absolute top-0 right-0">
                <ion-button
                  (click)="deleteNewImg(img)"
                  size="small"
                  shape="round"
                  fill="clear"
                  ><ion-icon
                    color="danger"
                    size="large"
                    slot="icon-only"
                    name="close-circle-outline"
                  ></ion-icon
                ></ion-button>
              </div>
            </div>
          </ion-card>
        </ion-col>
      </ion-row>
    </ion-grid>
  </form>
</ion-content>
