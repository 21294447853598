import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Wishlist } from 'src/app/models/wishlist.model';

@Injectable({
  providedIn: 'root'
})
export class WishlistService {

  path = '/api/Wishlist';

    constructor(
    private http: HttpClient
    ) { }

    removeWishlistItem(WishlistItem: Wishlist): Observable<any> {
        return this.http.delete(`${environment.API}${this.path}/${WishlistItem.id}`)
    }

    addWishlistItem(productId: string, userId: string): Observable<any> {
        var body = {
            productId: productId,
            userId: userId
        }
        return this.http.post(`${environment.API}${this.path}`, body)
    }
}
