<ion-content [fullscreen]="true">
  <ion-card class="flex flex-col justify-center">
    <ion-card-header>
      <ion-card-title> Verify Account</ion-card-title>
      <ion-progress-bar type="indeterminate" *ngIf="loading"></ion-progress-bar>
      <ion-card-subtitle
        >An email with a 4-digit code was sent to your email address. Enter the
        code in the field below to verify your account. Once verified, your
        account will be activated, and you'll be directed to the home page.
      </ion-card-subtitle>
    </ion-card-header>
    <div [formGroup]="verificationForm">
      <ion-item>
        <ion-input
          label="Email Address"
          labelPlacement="floating"
          formControlName="email"
        ></ion-input>

        <ion-text
          color="danger"
          *ngIf="verificationForm.get('email')?.hasError('whiteSpace')"
        >
          Must include more than white space.
        </ion-text>

        <ion-text
          color="danger"
          *ngIf="verificationForm.get('email')?.hasError('noEduEmail')  || verificationForm.get('email')?.hasError('email') 
  "
        >
          A valid edu email is required.
        </ion-text>
      </ion-item>

      <ion-item>
        <ion-input
          label="Verification Code"
          labelPlacement="floating"
          [counter]="true"
          maxlength="4"
          formControlName="verificationCode"
        ></ion-input>

        <ion-text
          color="danger"
          *ngIf="verificationForm.get('verificationCode')?.hasError('whiteSpace')"
        >
          Must include more than white space.
        </ion-text>
      </ion-item>
      <div class="flex justify-between">
        <ion-button
          (click)="onResend()"
          fill="clear"
          [disabled]="this.verificationForm.controls.email.invalid"
        >
          Resend Code
        </ion-button>
        <ion-button
          [disabled]="verificationForm.invalid || verificationForm.controls.verificationCode.value!.length < 4"
          (click)="onVerify()"
        >
          Verify
        </ion-button>
      </div>
    </div>
  </ion-card>
</ion-content>
