<ion-content [fullscreen]="true">
  <ion-grid>
    <ion-row size="8" class="search-funnel-filter">
      <ion-col
        ><ion-searchbar (ionInput)="filterItems($event)"></ion-searchbar
      ></ion-col>

      <ion-col size="2">
        <ion-button fill="clear" (click)="productSort()">
          <ion-icon name="filter"></ion-icon>
        </ion-button>
      </ion-col>

      <ion-col size="2">
        <ion-button fill="clear" (click)="openFilterAlert()">
          <ion-icon name="funnel"></ion-icon>
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>

  <div *ngIf="products.length === 0" class="empty-marketplace-div">
    <ion-icon name="bag-outline" class="empty-marketplace-icon"> </ion-icon>

    <ion-label class="empty-marketplace-label">
      <h2>There are currently no items for sale.</h2>
    </ion-label>
  </div>

  <ion-grid>
    <ion-row>
      <ion-col *ngFor="let item of products" size="6">
        <ion-card>
          <div class="w-full flex justify-center">
            <img
              class="flex max-h-20"
              (click)="navigateToProductDetails(item.id!)"
              *ngIf="item.images && item.images.length !== 0; else elseBlock"
              src="{{item.images[0].url}}"
            />
          </div>

          <ng-template #elseBlock>
            <img
              alt="Silhouette of mountains"
              src="https://ionicframework.com/docs/img/demos/card-media.png"
              (click)="navigateToProductDetails(item.id!)"
            />
          </ng-template>

          <ion-card-header>
            <ion-card-title (click)="navigateToProductDetails(item.id!)"
              >{{item.title}}</ion-card-title
            >
            <div class="price-heart">
              <ion-card-title>${{item.price}}</ion-card-title>
              <ion-button
                [disabled]="item.createdById === user.userId"
                shape="round"
                fill="clear"
                (click)="heartButtonClick(item)"
              >
                <ion-icon
                  slot="icon-only"
                  [name]="item.onWishlist ? 'heart' : 'heart-outline'"
                  size="large"
                  color="secondary"
                ></ion-icon>
              </ion-button>
            </div>
          </ion-card-header>
        </ion-card>
      </ion-col>
    </ion-row>
  </ion-grid>

  <ion-fab slot="fixed" class="top" vertical="bottom" horizontal="end">
    <ion-fab-button
      expand="block"
      (click)="openCreateModal()"
      id="present-alert"
    >
      <ion-icon size="large" name="add-outline"></ion-icon>
    </ion-fab-button>
  </ion-fab>
</ion-content>
