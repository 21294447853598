import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { addIcons } from 'ionicons';
import {
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonCardContent,
  IonList,
  IonItem,
  IonLabel,
  IonIcon,
  IonButton,
} from '@ionic/angular/standalone';
import { mailOutline } from 'ionicons/icons';

@Component({
  selector: 'app-jobs-internships',
  templateUrl: './jobs-internships.page.html',
  styleUrls: ['./jobs-internships.page.scss'],
  standalone: true,
  imports: [
    IonButton,
    IonIcon,
    IonLabel,
    IonItem,
    IonList,
    IonCardContent,
    IonCardSubtitle,
    IonCardTitle,
    IonCardHeader,
    IonCard,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    CommonModule,
    FormsModule,
  ],
})
export class JobsInternshipsPage {
  jobOpportunities = [
    {
      title: 'Cashier',
      businessName: "John's Kwikstop",
      street: '515 Hollard St',
      city: 'Altoona',
      state: 'PA',
      zipCode: '28342',
      amount: '12',
      employmentType: 'Part-Time',
      payType: 'hourly',
    },
    {
      title: 'Security Guard',
      businessName: 'The Rally Club',
      street: '123 Maple st',
      city: 'Altoona',
      state: 'PA',
      zipCode: '12345',
      amount: '11',
      employmentType: 'Part-Time',
      payType: 'hourly',
    },
    {
      title: 'Deli Clerk',
      businessName: "Gigi's Cuts",
      street: '456 College Ave',
      city: 'Altoona',
      state: 'PA',
      zipCode: '12345',
      amount: '12.50',
      employmentType: 'Flexible Hours',
      payType: 'hourly',
    },
    {
      title: 'Waiter/Waitress',
      businessName: 'The College Stop',
      street: '518 Hollard St',
      city: 'Wilmington',
      state: 'NC',
      zipCode: '28403',
      amount: '2.50',
      employmentType: 'Full-Time',
      payType: 'hourly',
    },
  ];
  constructor() {
    addIcons({ mailOutline });
  }
}
