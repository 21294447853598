import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { addIcons } from 'ionicons';
import {
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonCardContent,
  IonButton,
  IonIcon,
  IonModal,
  IonList,
  IonItem,
  IonButtons,
  ModalController,
} from '@ionic/angular/standalone';
import { pricetagOutline, listOutline } from 'ionicons/icons';
import { Deal } from 'src/app/models/deal.model';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { DealService } from 'src/app/services/deal.service';
import { ViewDealModalComponent } from 'src/app/modals/view-deal-modal/view-deal-modal.component';
import { Subscription } from 'rxjs';
import { AppService } from 'src/app/services/app.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-local-deals',
  templateUrl: './local-deals.page.html',
  styleUrls: ['./local-deals.page.scss'],
  standalone: true,
  imports: [
    IonButtons,
    IonItem,
    IonList,
    IonModal,
    IonIcon,
    IonButton,
    IonCardContent,
    IonCardSubtitle,
    IonCardTitle,
    IonCardHeader,
    IonCard,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    CommonModule,
    FormsModule,
    IonItem,
    IonList,
    IonButtons,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class LocalDealsPage implements OnInit, OnDestroy {
  isModalOpen: boolean = false;

  deals: Deal[] = [];

  backSubscription?: Subscription;

  constructor(
    private dealService: DealService,
    private modalController: ModalController,
    private router: Router,
    private appService: AppService,
  ) {
    addIcons({ listOutline, pricetagOutline });
  }

  ngOnInit(): void {
    this.backSubscription = this.appService.backSubject.subscribe(_ => {
      this.router.navigateByUrl('/home')
    })

    this.dealService
      .getDeals()
      .subscribe((response) => (this.deals = response));
  }

  ngOnDestroy(): void {
    if (this.backSubscription) {
      this.backSubscription.unsubscribe();
    }
  }

  async openViewDealModal(deal: Deal) {
    const modal = await this.modalController.create({
      component: ViewDealModalComponent,
      componentProps: {
        deal: deal,
      },
    });
    modal.present();
  }
}
