import { Injectable } from "@angular/core";
import { ToastController } from "@ionic/angular";

@Injectable({
    providedIn: 'root',
  })
export class ToastService {
    constructor(private toastController: ToastController) {}
    
    async presentToast(message: string, duration: number = 1500, position: 'top' | 'middle' | 'bottom' = 'bottom') {
        const toast = await this.toastController.create({
          message: message,
          duration: duration,
          position: position,
        });
    
        await toast.present();
    }
}