import { Injectable } from '@angular/core';
import { UserVerification } from '../models/userVerification.model';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { User } from '../models/user.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserVerificationService {
  path = '/api/UserVerification';
  userEmail!: string;

  constructor(private http: HttpClient) {}

  deleteVerification(verification: UserVerification) {
    return this.http.delete(
      `${environment.API}${this.path}/${verification.id}`,
    );
  }

  updateVerificationCode(body: string): Observable<UserVerification> {
    return this.http.put<UserVerification>(
      `${environment.API}${this.path}/ResendUserVerification`,
      { email: body },
    );
  }
}
