import { Injectable } from '@angular/core';
import { TokenModel } from '../models/user.model';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

const KEY_AUTH = "TheBiziAppAuthToken";
const KEY_REFRESH = "TheBiziAppRefreshToken";

@Injectable({
    providedIn: 'root'
})
export class TokenService {
    get refreshToken(): string { return this.getRefreshToken(); }
    get authToken(): string { return this.getAuthToken(); }

    constructor(private router: Router, private snackbar: MatSnackBar) {}

    public updateTokens(tokens: TokenModel): Promise<void> {
        return new Promise((resolve) => {
            window.localStorage.setItem(KEY_AUTH, tokens.token);
            window.localStorage.setItem(KEY_REFRESH, tokens.refreshToken);
            resolve();
        });
    }

    private getAuthToken(): string {
        return window.localStorage.getItem(KEY_AUTH) ?? "";
    }

    private getRefreshToken(): string {
        return window.localStorage.getItem(KEY_REFRESH) ?? "";
    }

  public logout(showExpired = false): void {
    window.localStorage.clear();
    this.router.navigateByUrl('/login');
  }
}
