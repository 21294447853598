import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { IonContent, IonHeader, IonTitle, IonToolbar, IonItem, IonInput, IonButton, IonText, IonLabel, IonProgressBar, IonToast } from '@ionic/angular/standalone';
import { RouterModule } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.page.html',
  styleUrls: ['./forgot-password.page.scss'],
  standalone: true,
  imports: [IonToast, IonProgressBar, IonLabel, IonText, IonButton, IonInput, IonItem, IonContent, IonHeader, IonTitle, IonToolbar, CommonModule, FormsModule, RouterModule, ReactiveFormsModule]
})
export class ForgotPasswordPage{
  forgotPasswordForm: FormGroup = new FormGroup({
    email: new FormControl(null, [Validators.required, Validators.email])
  })
  
  loading = false; 

  constructor(
    private userService: UserService,
    private toastService: ToastService
  ) { }

  onForgotPasswordClick(): void {
    this.loading = true;

    this.userService
      .forgotPassword(this.forgotPasswordForm.value.email)
      .subscribe({
        next: () => {
          this.loading = false;
          this.toastService.presentToast('Your request has been received. If a matching email address exists in our system a password reset email will be sent.', 5000)
        },
        error: () => {
          this.loading = false;
          this.toastService.presentToast('An error has occurred, please try again.', 5000)
        },
      });
  }
}
