import { Injectable, signal } from '@angular/core';
import { Subject } from 'rxjs';
import * as signalR from '@microsoft/signalr';
import { environment } from 'src/environments/environment';
import { CustomHttpClient } from '../signalr/custom-http-client';
import { Logger } from '../signalr/custom-logger';
import { TokenService } from './token.service';

@Injectable({
  providedIn: 'root',
})
export class ChatsService {
  connection?: signalR.HubConnection;
  messagesSubject: Subject<{ conversationId: string; message: string }> =
    new Subject();
  errorSubject: Subject<any> = new Subject();

  refreshSubject: Subject<boolean> = new Subject();

  logger: signalR.ILogger;

  constructor(private tokenService: TokenService) {
    this.logger = new Logger();
  }

  connect() {
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(`${environment.API}/hub`, {
        httpClient: new CustomHttpClient(
          this.logger,
          this.tokenService.authToken,
        ),
        accessTokenFactory: () => this.tokenService.authToken,
      })
      .build();

    this.connection.on(
      'messageReceived',
      (conversationId: string, message: string) => {
        this.messagesSubject.next({
          conversationId: conversationId,
          message: message,
        });
      },
    );

    this.connection.start().catch((err) => this.errorSubject.next(err));
  }

  sendMessage(message: string, conversationId: string) {
    if (this.connection) {
      this.connection
        .send('newMessage', conversationId, message)
        .then(() => {
          this.refreshSubject.next(false);
        });
    }
  }

  disconnect() {
    if (this.connection) {
      this.connection.off('messageReceived');
      this.connection.stop();
    }
  }
}
