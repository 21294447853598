import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const WhiteSpaceCheckValidator: ValidatorFn = (
  control: AbstractControl,
): ValidationErrors | null => {
  const value = control.value || '';

  if (value.trim().length == 0 && value != '') {
    return { whiteSpace: true };
  }

  return null;
};
