import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonButton,
  IonIcon,
  IonList,
  IonItem,
  IonLabel,
  IonGrid,
  IonItemDivider,
  IonInput,
  IonFooter,
  IonItemSliding,
  IonItemOptions,
  IonItemOption,
} from '@ionic/angular/standalone';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { addIcons } from 'ionicons';
import { send, chevronBackOutline, trash } from 'ionicons/icons';
import { AppService } from 'src/app/services/app.service';
import { ChatsService } from 'src/app/services/chats.service';
import { Subscription } from 'rxjs';
import { ConversationService } from 'src/app/services/conversation.service';
import { MessageService } from 'src/app/services/message.service';
import { Message } from 'src/app/models/chat.model';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.page.html',
  styleUrls: ['./chat.page.scss'],
  standalone: true,
  imports: [
    IonItemOption,
    IonItemOptions,
    IonItemSliding,
    IonFooter,
    IonInput,
    IonItemDivider,
    IonGrid,
    IonLabel,
    IonItem,
    IonList,
    IonIcon,
    IonButton,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    CommonModule,
    FormsModule,
    RouterModule,
  ],
})
export class ChatPage {
  chats!: Message[];
  message: string = '';
  conversationId: string = 'A331BDC9-6B9F-4986-A706-BE8C8FEDAE24';
  
  backSubscription?: Subscription;
  messageSubscription?: Subscription;
  refreshSubscription?: Subscription;

  constructor(
    private appService: AppService,
    private chatsService: ChatsService,
    private conversationService: ConversationService,
    private messageService: MessageService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) {
    addIcons({ chevronBackOutline, trash, send });
  }

  ionViewWillEnter(): void {
    let pageTitle = '';
    this.appService.setPageTitle(pageTitle);

    this.listenForChats();

    if (!this.backSubscription) {
      this.backSubscription = this.appService.backSubject.subscribe((_) => {
        this.router.navigateByUrl('/my-chats');
      });
    }

    this.activatedRoute.params.subscribe((params) => {
      this.conversationService
        .getConversation(params['id'])
        .subscribe((conversation) => {
          var names: string[] = [];

          conversation.conversationUsers.forEach((conversationUser) => {
            names.push(conversationUser.user.firstName);
          });
          this.appService.setPageTitle(names.join(', '));

          this.conversationId = conversation.id!;
          this.getMessages();
        });
    });
  }

  ionViewDidLeave(): void {
    if (this.backSubscription) {
      this.backSubscription.unsubscribe();
      this.backSubscription = undefined;
    }

    if (this.messageSubscription) {
      this.messageSubscription.unsubscribe();
      this.messageSubscription = undefined;
    }

    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
      this.refreshSubscription = undefined;
    }

    this.chatsService.disconnect();
  }

  listenForChats() {
    this.messageSubscription = this.chatsService.messagesSubject.subscribe((message) => {
      this.getMessages();
    });

    this.refreshSubscription = this.chatsService.refreshSubject.subscribe(_ => {
      this.getMessages();
    })

    this.chatsService.errorSubject.subscribe((err) => {});

    this.chatsService.connect();
  }

  getMessages() {
    this.messageService
      .getMessagesByConversation(this.conversationId)
      .subscribe((messages) => {
        this.chats = messages;
      });
  }

  send() {
    this.chatsService.sendMessage(this.message, this.conversationId);
    this.message = '';
  }

  deleteMsg(chatId: any) {}
}
