import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Conversation } from '../models/chat.model';
import { PatchDocument } from '../models/patch.model';

@Injectable({
  providedIn: 'root',
})
export class ConversationService {
  updatableProperties: string[] = ['status'];

  path = '/api/Conversation';

  constructor(private http: HttpClient) {}

  getConversations(queryParams?: Map<string, any>): Observable<Conversation[]> {
    const queryParametersString = this.buildQueryParameters(queryParams);
    return this.http.get<Conversation[]>(
      `${environment.API}${this.path}/${queryParametersString}`,
    );
  }

  getConversation(id: string): Observable<Conversation> {
    return this.http.get<Conversation>(`${environment.API}${this.path}/${id}`);
  }

  createConversation(userId: string): Observable<any> {
    return this.http.post<any>(`${environment.API}${this.path}/CreateChat`, {
      userId,
    });
  }

  updateConversationUser(body: Conversation): Observable<Conversation> {
    const url = `${environment.API}${this.path}User/${body.id}`;
    const patchDoc = this.toPatchDoc(body);
    return this.http.patch<Conversation>(url, patchDoc, {
      headers: new HttpHeaders({
        Accept: 'application/json',
      }),
    });
  }

  createGroupChat(userIds: string[]): Observable<Conversation> {
    return this.http.post<Conversation>(`${environment.API}${this.path}/Group`, userIds);
  }

  protected toPatchDoc(object: Conversation): PatchDocument[] {
    let doc: PatchDocument[] = [];

    if (this.updatableProperties) {
      for (const [key, value] of Object.entries(object)) {
        if (this.updatableProperties.includes(key)) {
          doc.push({
            op: 'replace',
            path: `/${key}`,
            value: value,
          });
        }
      }
    }

    return doc;
  }

  protected buildQueryParameters(queryParameters?: Map<string, any>): string {
    if (queryParameters == null || queryParameters.size == 0) {
      return '';
    }

    let queryString = '?';

    for (let entry of queryParameters.entries()) {
      if (typeof entry[1] == 'object') {
        for (let val of Array.from(entry[1])) {
          queryString += entry[0] + '=' + val + '&';
        }

        queryString = queryString.substring(0, queryString.length - 1);
      } else {
        queryString += entry[0] + '=' + queryParameters.get(entry[0]);
      }
    }

    return queryString;
  }
}
