<ion-content>
  <ion-header>
    <ion-toolbar color="primary">
      <ion-title class="flex justify-center">{{ deal.title }}</ion-title>
      
      <ion-buttons slot="end">
        <ion-button color="light" [strong]="true" (click)="cancel()">Close</ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>

  <ion-list>
    <ion-item>
      <swiper-container #swiper zoom="true">
        <swiper-slide>
          <div class="swiper-zoom-container">
            <img [src]="deal.url" />
          </div>
        </swiper-slide>
      </swiper-container>
    </ion-item>

    <ion-item>
      <p>{{ deal.description }}</p>
    </ion-item>
  </ion-list>
</ion-content>
