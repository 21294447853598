import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonIcon,
  IonTitle,
  IonContent,
  IonProgressBar,
  IonList,
  IonItem,
  IonRadioGroup,
  IonRadio,
  IonTextarea,
  ModalController,
} from '@ionic/angular/standalone';
import { CommentService } from 'src/app/services/comment.service';
import { PostService } from 'src/app/services/post.service';
import { ToastService } from 'src/app/services/toast.service';

interface ReportForm {
  reason: FormControl<string | null>,
  other: FormControl<string | null>
}

@Component({
  selector: 'app-report-modal',
  templateUrl: './report-modal.component.html',
  styleUrls: ['./report-modal.component.scss'],
  standalone: true,
  imports: [
    IonTextarea,
    IonRadio,
    IonItem,
    IonList,
    IonProgressBar,
    IonContent,
    IonTitle,
    IonIcon,
    IonButton,
    IonButtons,
    IonHeader,
    IonToolbar,
    CommonModule,
    IonRadioGroup,
    ReactiveFormsModule,
  ],
})
export class ReportModalComponent {
  @Input() id: string | undefined;
  @Input() isComment: boolean = false;

  loading: boolean = false;

  reportForm = new FormGroup<ReportForm>({
    reason: new FormControl(null, [ Validators.required ]),
    other: new FormControl(null, [ Validators.required ]),
  })

  constructor(
    private toastService: ToastService,
    private modalController: ModalController,
    private postService: PostService,
    private commentService: CommentService,
  ) {}

  get reportReason() {
    return this.reportForm.controls.reason;
  }

  get otherReason() {
    return this.reportForm.controls.other;
  }

  cancel() {
    return this.modalController.dismiss(null, 'cancel');
  }

  confirm() {
    return this.modalController.dismiss(null, 'confirm');
  }

  isSubmitDisabled(): boolean {
    if (this.reportForm.controls.reason.value === 'other') {
      return this.reportForm.controls.other.invalid;
    }
    return this.reportForm.controls.reason.invalid;
  }

  submitReport() {
    this.loading = true;

    let reason = this.reportForm.controls.reason.value ?? "";
    
    if (this.reportForm.controls.reason.invalid) {
      this.toastService.presentToast("You must provide a reason.");
      return;
    }

    if (reason == 'other') {
      if (this.reportForm.controls.other.invalid) {
        this.toastService.presentToast("You must provide a reason.");
        return;
      }

      reason = this.reportForm.controls.other.value ?? "";
    }

    var service: PostService | CommentService = this.isComment 
      ? this.commentService 
      : this.postService;

    if (this.id) {
      service.report(this.id, reason).subscribe({
        next: _ => {
          this.loading = false;
          this.toastService.presentToast('Your report was submitted for review.');
          this.confirm();
        },
        error: err => {
          this.loading = false;
          this.toastService.presentToast('Failed to submit your report.');
        }
      });
    }
  }
}
