<ion-progress-bar type="indeterminate" *ngIf="loading"></ion-progress-bar>
@if(item) {
<div class="overflow-scroll h-full">
  <swiper-container zoom="true" #swiper [thumbs]="{swiper: swiperDoc}">
    <swiper-slide *ngFor="let image of item.images" class="swiper-slide">
      <div class="swiper-zoom-container">
        <img [src]="image.url" class="main-image" />
      </div>
    </swiper-slide>
  </swiper-container>
  <swiper-container
    #thumbsSwiper
    id="thumbsSwiper"
    [slidesPerView]="4"
    class="thumbSwiper"
  >
    <swiper-slide
      *ngFor="let image of item.images"
      (click)="onThumbClick(image)"
    >
      <img [src]="image.url" />
    </swiper-slide>
  </swiper-container>

  <ion-list lines="none">
    <ion-item>
      <div class="w-1/5">
        <ion-button
          [disabled]="item.createdById == user?.userId"
          class="flex justify-center"
          fill="clear"
          shape="round"
          (click)="heartButtonClick(item)"
        >
          <ion-icon
            class="mr-1"
            [name]="item.onWishlist ? 'heart' : 'heart-outline'"
            size="large"
            color="secondary"
          ></ion-icon>
        </ion-button>
      </div>

      <ion-label>
        <h1 class="flex justify-center">{{item.title}}</h1>
      </ion-label>

      <div class="w-1/5">
        <ion-button
          *ngIf="item.createdById == user?.userId"
          (click)="editModal(item)"
          class="flex justify-center"
          fill="clear"
          shape="round"
        >
          <ion-icon
            class="ml-1"
            name="pencil-outline"
            size="large"
            color="secondary"
          ></ion-icon>
        </ion-button>
      </div>
    </ion-item>

    <ion-item>
      <ion-label>
        <div class="flex justify-evenly">
          <p>${{item.price}}</p>
          <p>{{item.category?.name}}</p>
        </div>
        <p class="flex justify-center">
          Listed by: {{seller?.firstName}} {{seller?.lastName}}
        </p>
        <p class="description">{{item.description}}</p>
      </ion-label>
    </ion-item>

    <ion-item>
      <ion-label>Rate Seller:</ion-label>
      <ion-button
        *ngFor="let star of stars"
        (click)="updateSellerRating(star.num)"
        fill="clear"
        [disabled]="item.createdById == user?.userId"
      >
        <ion-icon [name]="star.name" color="secondary" size="large"></ion-icon>
      </ion-button>
    </ion-item>

    <ion-item>
      <ion-input
        placeholder="Make an Offer.."
        fill="outline"
        [(ngModel)]="messageSeller"
        [disabled]="item.createdById == user?.userId"
      ></ion-input>
      <ion-button
        fill="clear"
        slot="end"
        [disabled]="item.createdById == user?.userId"
        (click)="sendMessage()"
      >
        <ion-icon name="send"></ion-icon>
      </ion-button>
    </ion-item>
  </ion-list>
</div>
}
