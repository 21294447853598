import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, throwError } from 'rxjs';
// import { LoginService } from '../services/login.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TokenService } from '../services/token.service';
import { AuthService } from '../services/auth.service';

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  const router: Router = inject(Router);
  const tokenService: TokenService = inject(TokenService);
  const authService: AuthService = inject(AuthService);
  const snackbar: MatSnackBar = inject(MatSnackBar);

  // const user = loginService.getLoginData();
  const token = tokenService.authToken;
  let requestToSend = req;

  if (
    token &&
    // If on login
    req.headers.get('Authorization') == null &&
    // If on refresh
    req.headers.get('RefreshToken') == null
  ) {
    requestToSend = req.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  return next(requestToSend).pipe(
    catchError((err: any) => {
      if (err instanceof HttpErrorResponse) {
        // Handle HTTP errors
        if (err.status === 401) {
          let refreshToken = tokenService.refreshToken;
          authService.refreshToken(refreshToken).subscribe({
            next: (response) => {
              tokenService.updateTokens(response);
              req.headers.set('Authorization', `Bearer ${response.token}`);
              next(req);
            },
            error: () => {
              router.navigateByUrl('/login');
            },
          });
        }

        if (err.status == 403) {
          snackbar.open(
            'You do not have permission to view this resource.',
            undefined,
            {
              duration: 2000,
            },
          );
          router.navigateByUrl('/login');
        }
      }

      // Re-throw the error to propagate it further
      return throwError(() => err);
    }),
  );
};
