import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { addIcons } from 'ionicons';
import { MeService } from 'src/app/services/me.service';
import {
  business,
  at,
  home,
  school,
  personCircle,
  star,
  starOutline,
  starHalf,
  bookmark,
} from 'ionicons/icons';
import {
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonList,
  IonItem,
  IonIcon,
  IonLabel,
  IonButton,
  IonItemOptions,
  IonItemOption,
  IonTextarea,
  IonText,
  IonAlert,
} from '@ionic/angular/standalone';
import { Router, RouterModule } from '@angular/router';
import { UserProfile } from 'src/app/models/user-profile.model';
import { TokenService } from 'src/app/services/token.service';
import { Subscription } from 'rxjs';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.page.html',
  styleUrls: ['./my-profile.page.scss'],
  standalone: true,
  imports: [
    IonAlert,
    IonText,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    CommonModule,
    FormsModule,
    IonList,
    IonItem,
    IonIcon,
    IonLabel,
    IonButton,
    IonItemOptions,
    IonItemOption,
    IonTextarea,
    RouterModule,
  ],
})
export class MyProfilePage implements OnInit, OnDestroy {
  rating: number = 0;
  stars: string[] = [];
  toggleTheme: boolean = false;
  userProfile!: UserProfile;

  backSubscription?: Subscription

  public alertButtons = [
    {
      text: 'Cancel',
      role: 'cancel',
    },
    {
      text: 'Yes',
      role: 'confirm',
      handler: () => {
        this.router.navigateByUrl('/login');
      },
    },
  ];

  constructor(
    private meService: MeService,
    private router: Router,
    private tokenService: TokenService,
    private appService: AppService,
  ) {
    addIcons({
      business,
      at,
      school,
      personCircle,
      bookmark,
      star,
      starHalf,
      starOutline,
    });
  }

  ngOnInit(): void {
    this.backSubscription = this.appService.backSubject.subscribe(_ => {
      this.router.navigateByUrl('/home')
    });

    this.meService.getUserProfile().subscribe((response: UserProfile) => {
      this.userProfile = response;
    });

    this.meService.getMyRating().subscribe((response) => {
      this.rating = response;
      this.handleRating();
    });
  }

  ngOnDestroy(): void {
    if (this.backSubscription) {
      this.backSubscription.unsubscribe();
    }
  }

  handleRating(): void {
    let roundedRating = Math.round(this.rating * 2) / 2;
    for (let i = 1; i <= 5; i++) {
      if (i <= roundedRating) {
        this.stars.push('star');
      } else if (i - 0.5 === roundedRating) {
        this.stars.push('star-half');
      } else {
        this.stars.push('star-outline');
      }
    }
  }

  logout() {
    this.tokenService.logout();
  }

  getUserCampus() {
    if (this.userProfile.campus != null) {
      return this.userProfile.campus?.college.name;
    }

    return '';
  }
}
