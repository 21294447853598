import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { addIcons } from 'ionicons';
import { heart, heartOutline, listOutline, bagOutline } from 'ionicons/icons';
import {
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonList,
  IonItem,
  IonLabel,
  IonIcon,
  IonButton,
  AlertController,
} from '@ionic/angular/standalone';
import { MeService } from 'src/app/services/me.service';
import { Router, RouterModule } from '@angular/router';
import { Wishlist } from 'src/app/models/wishlist.model';
import { Subscription } from 'rxjs';
import { AppService } from 'src/app/services/app.service';
import { WishlistService } from 'src/app/services/wishlist.service';

@Component({
  selector: 'app-wish-list',
  templateUrl: './wish-list.page.html',
  styleUrls: ['./wish-list.page.scss'],
  standalone: true,
  imports: [
    IonButton,
    IonLabel,
    IonItem,
    IonList,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    CommonModule,
    FormsModule,
    IonIcon,
    RouterModule,
  ],
})
export class WishListPage implements OnInit, OnDestroy {
  itemsToRemove: Wishlist[] = [];
  myWishlist: Wishlist[] = [];

  backSubscription?: Subscription;

  public alertButtons = [
    {
      text: 'Cancel',
      role: 'cancel',
    },
    {
      text: 'Create',
      role: 'confirm',
      handler: () => {},
      //use a service here to pass new product to the backend
    },
  ];

  public alertInputs = [
    {
      placeholder: 'Title',
    },
    {
      placeholder: 'Price',
    },
  ];

  constructor(
    private wishlistService: WishlistService,
    private meService: MeService,
    private router: Router,
    private appService: AppService,
  ) {
    addIcons({ listOutline, bagOutline, heart, heartOutline });
  }

  ngOnInit(): void {
    this.backSubscription = this.appService.backSubject.subscribe(_ => {
      this.router.navigateByUrl('/home');
    });

    this.meService.getWishListItems().subscribe((response: Wishlist[]) => {
      this.myWishlist = response;
    });
  }

  heartClick(item: Wishlist) {
    if (!this.itemsToRemove.includes(item)) {
      this.itemsToRemove.push(item);
    } else {
      this.itemsToRemove = this.itemsToRemove.filter(
        (itemArray) => itemArray !== item,
      );
    }
  }

  ngOnDestroy(): void {
    this.itemsToRemove.forEach((item) => {
      this.wishlistService.removeWishlistItem(item).subscribe();
    });

    if (this.backSubscription) {
      this.backSubscription.unsubscribe();
    }
  }

  navigateToProductDetails(id: string) {
    this.router.navigate([`/product-details`, `${id}`]);
  }
}
