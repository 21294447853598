<ion-header [translucent]="true">
  <ion-toolbar>
    <ion-title>apartmentsHousing</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true">
  <ion-header collapse="condense">
    <ion-toolbar>
      <ion-title size="large">apartmentsHousing</ion-title>
    </ion-toolbar>
  </ion-header>
</ion-content>
