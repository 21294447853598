import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Product } from '../models/product.model';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PatchDocument } from '../models/patch.model';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  path = '/api/Product';

  updatableProperties: string[] = [
    'title',
    'description',
    'price',
    'categoryId',
    'status',
  ];

  constructor(private http: HttpClient) {}

  getProduct(id: string): Observable<Product> {
    return this.http.get<Product>(`${environment.API}${this.path}/${id}`);
  }

  getProducts(queryParams?: Map<string, any>): Observable<any> {
    const queryParametersString = this.buildQueryParameters(queryParams);
    return this.http.get<any>(
      `${environment.API}${this.path}${queryParametersString}`,
    );
  }

  createProduct(product: Product): Observable<any> {
    return this.http.post<any>(`${environment.API}${this.path}`, product);
  }

  uploadProductImages(productId: string, filesToUpload: File[] = []) {
    const formData = new FormData();
    for (let image of filesToUpload) {
      formData.append(image.name, image);
    }

    formData.append('productId', productId);

    return this.http.post<any>(
      `${environment.API}/api/Storage/Product`,
      formData,
    );
  }

  protected buildQueryParameters(queryParameters?: Map<string, any>): string {
    if (queryParameters == null || queryParameters.size == 0) {
      return '';
    }

    let queryString = '?';

    for (let entry of queryParameters.entries()) {
      if (typeof entry[1] == 'object') {
        for (let val of Array.from(entry[1])) {
          queryString += entry[0] + '=' + val + '&';
        }
      } else {
        queryString += entry[0] + '=' + queryParameters.get(entry[0]) + '&';
      }
    }

    queryString = queryString.substring(0, queryString.length - 1);

    return queryString;
  }

  updateProduct(product: Product) {
    const url = `${environment.API}${this.path}/${product.id}`;
    const patchDoc = this.toPatchDoc(product);

    return this.http.patch<Product>(url, patchDoc, {
      headers: new HttpHeaders({
        Accept: 'application/json',
      }),
    });
  }

  deleteProductImage(productId: string, queryParams: Map<string, any>) {
    const queryParametersString = this.buildQueryParameters(queryParams);

    return this.http.delete<any>(
      `${environment.API}/api/Storage/Product/${productId}/${queryParametersString}`,
    );
  }

  protected toPatchDoc(object: Product): PatchDocument[] {
    let doc: PatchDocument[] = [];

    if (this.updatableProperties) {
      for (const [key, value] of Object.entries(object)) {
        if (this.updatableProperties.includes(key)) {
          doc.push({
            op: 'replace',
            path: `/${key}`,
            value: value,
          });
        }
      }
    }

    return doc;
  }
}
