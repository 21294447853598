import {
  Component,
  computed,
  OnDestroy,
  OnInit,
  Signal,
  signal,
  WritableSignal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormControl,
  FormGroup,
  FormsModule,
  Validators,
} from '@angular/forms';
import { addIcons } from 'ionicons';
import { funnel, filter, heart, addOutline, bagOutline } from 'ionicons/icons';
import {
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonItem,
  IonLabel,
  IonSearchbar,
  IonIcon,
  IonAlert,
  IonFab,
  IonFabButton,
  ModalController,
  IonModal,
  IonButtons,
  AlertController,
  AlertInput,
} from '@ionic/angular/standalone';
import { ProductService } from 'src/app/services/products.service';
import { Product } from 'src/app/models/product.model';
import { Router, RouterModule } from '@angular/router';
import { CreateProductModalComponent } from 'src/app/modals/create-product-modal/create-product-modal.component';
import { Wishlist } from 'src/app/models/wishlist.model';
import { MeService } from 'src/app/services/me.service';
import { UserProfile } from 'src/app/models/user-profile.model';
import { WishlistService } from 'src/app/services/wishlist.service';
import { CategoryService } from 'src/app/services/category.service';
import { Category } from 'src/app/models/category.model';
import { ProductDetailsPage } from './product-details/product-details.page';
import { UserService } from 'src/app/services/user.service';
import { AppService } from 'src/app/services/app.service';
import {
  debounceTime,
  distinctUntilChanged,
  Subject,
  Subscription,
} from 'rxjs';
import { LoadingService } from 'src/app/services/loading.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-shop-products',
  templateUrl: './shop-products.page.html',
  styleUrls: ['./shop-products.page.scss'],
  standalone: true,
  imports: [
    IonButtons,
    IonModal,
    IonFabButton,
    IonFab,
    IonAlert,
    IonIcon,
    IonSearchbar,
    IonLabel,
    IonItem,
    IonButton,
    IonCol,
    IonRow,
    IonGrid,
    IonCardContent,
    IonCardSubtitle,
    IonCardTitle,
    IonCardHeader,
    IonCard,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    CommonModule,
    FormsModule,
    RouterModule,
  ],
})
export class ShopProductsPage implements OnInit, OnDestroy {
  products: Product[] = [];
  wishlist: Wishlist[] = [];
  categories: any[] = [];
  filterOptions: WritableSignal<AlertInput[]> = signal([]);
  filteredItems = [...this.products];
  backSubscription?: Subscription;
  currentSort: WritableSignal<string> = signal('CreatedDate%20desc');
  currentFilter: WritableSignal<string[]> = signal([]);

  sortOptions: Signal<AlertInput[]> = computed(() => [
    {
      label: 'Newest First',
      type: 'radio',
      value: 'CreatedDate%20desc',
      checked: this.currentSort() == 'CreatedDate%20desc',
    },
    {
      label: 'Oldest First',
      type: 'radio',
      value: 'CreatedDate%20asc',
      checked: this.currentSort() == 'CreatedDate%20asc',
    },
    {
      label: 'Cost - Low to High',
      type: 'radio',
      value: 'Price%20asc',
      checked: this.currentSort() == 'Price%20asc',
    },
    {
      label: 'Cost - High to Low',
      type: 'radio',
      value: 'Price%20desc',
      checked: this.currentSort() == 'Price%20desc',
    },
    {
      label: 'Alphabetical (A to Z)',
      type: 'radio',
      value: 'Title%20asc',
      checked: this.currentSort() == 'Title%20asc',
    },
    {
      label: 'Alphabetical (Z to A)',
      type: 'radio',
      value: 'Title%20desc',
      checked: this.currentSort() == 'Title%20desc',
    },
  ]);

  private searchSubject$ = new Subject<string>();
  queryParams = new Map<string, any>();

  user = {} as UserProfile;

  createItemForm: FormGroup = new FormGroup({
    name: new FormControl(null, [Validators.required]),
    price: new FormControl(null, [Validators.required]),
    category: new FormControl(null, [Validators.required]),
  });

  public sortButtons = [
    {
      text: 'Apply',
      role: 'confirm',
      handler: (data: string) => {
        this.currentSort.set(data);
        this.queryParams.set('OrderBy', data);

        this.getProducts();
      },
    },
  ];

  public filterButtons = [
    {
      text: 'Apply',
      role: 'confirm',
      handler: (data: string[]) => {
        this.currentFilter.set(data);
        this.queryParams.set('categories', data);

        this.getProducts();
      },
    },
  ];

  constructor(
    private meService: MeService,
    private wishlistService: WishlistService,
    private productService: ProductService,
    private modalController: ModalController,
    private categoryService: CategoryService,
    private router: Router,
    private userService: UserService,
    private alertController: AlertController,
    private appService: AppService,
    private loading: LoadingService,
    private toastService: ToastService,
  ) {
    addIcons({ filter, funnel, addOutline, bagOutline, heart });
  }

  ngOnInit(): void {
    this.backSubscription = this.appService.backSubject.subscribe((_) => {
      this.router.navigateByUrl('/home');
    });

    this.getCategories();
    this.getProducts();

    this.meService
      .getUserProfile()
      .subscribe((response: UserProfile) => (this.user = response));

    this.categoryService.getCategories().subscribe((response: Category[]) => {
      var filterOptions = response.map<AlertInput>((item) => {
        return {
          label: item.name,
          value: item.id,
          type: 'checkbox',
          checked: false,
        };
      });
      this.filterOptions.set(filterOptions);
    });

    this.searchSubject$
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((text) => {
        if (text) {
          this.queryParams.set('title', text);
        }
        this.getProducts();
      });
  }

  ngOnDestroy(): void {
    if (this.backSubscription) {
      this.backSubscription.unsubscribe();
    }

    if (this.searchSubject$) {
      this.searchSubject$.complete();
    }
  }

  getProducts() {
    this.productService
      .getProducts(this.queryParams)
      .subscribe((products: Product[]) => {
        this.meService.getWishListItems().subscribe({
          next: (wishlistItems: Wishlist[]) => {
            this.wishlist = wishlistItems;

            wishlistItems.forEach((item: Wishlist) => {
              products.find((product) => {
                if (product.id == item.productId) {
                  product.onWishlist = true;
                }
              });
            });

            this.products = products;
            this.loading.hideLoading();
          },

          error: () => {
            this.loading.hideLoading();
          },
        });
      });
  }

  getCategories() {
    this.categoryService.getCategories().subscribe((response: Category[]) => {
      this.filterOptions.set(
        response.map<AlertInput>((category) => {
          return {
            label: category.name,
            value: category.id,
            type: 'checkbox',
            checked:
              this.currentFilter().find(
                (currentFilter) => currentFilter == category.id,
              ) != null,
          };
        }),
      );
    });
  }

  filterItems(event: CustomEvent) {
    const textValue = event.detail.value;
    this.searchSubject$.next(textValue);
  }

  async openCreateModal() {
    const modal = await this.modalController.create({
      component: CreateProductModalComponent,
    });
    modal.present();

    const { data, role } = await modal.onWillDismiss();
    if (role == 'confirm') {
      this.loading.showLoading('Please wait item saving...');
      var product: Product = {
        title: data.controls['name'].value,
        price: data.controls['price'].value,
        description: data.controls['description'].value,
        categoryId: data.controls['categoryId'].value,
        status: 'ACTIVE',
      };

      this.productService.createProduct(product).subscribe({
        next: (response: Product) => {
          if (data.controls['images'].value.length != 0) {
            this.productService
              .uploadProductImages(
                response.id ?? '',
                data.controls['images'].value,
              )
              .subscribe({
                next: () => {
                  this.getProducts();
                },

                error: () => {},
              });
          }
        },

        error: () => {
          this.loading.hideLoading();
        },
      });

      this.getProducts();
    }
  }

  async openViewProductModal(item: Product) {
    this.userService.getUser(item.createdById!).subscribe(async (user) => {
      const modal = await this.modalController.create({
        component: ProductDetailsPage,
        componentProps: {
          item: item,
          seller: user,
        },
      });
      modal.present();
    });
  }

  heartButtonClick(item: Product) {
    item.onWishlist = !item.onWishlist;
    let wishlistItem: Wishlist | undefined = this.wishlist.find(
      (wishlistItem) => wishlistItem.productId == item.id,
    );

    if (wishlistItem != undefined) {
      this.wishlistService
        .removeWishlistItem(wishlistItem)
        .pipe(debounceTime(1000), distinctUntilChanged())
        .subscribe({
          next: () => {
            this.wishlist = this.wishlist.filter(
              (item) => item.id != wishlistItem.id,
            );
          },
          error: () => {
            this.toastService.presentToast('An error as occurred.');
          },
        });
    } else {
      this.wishlistService
        .addWishlistItem(item.id!, this.user.userId)
        .pipe(debounceTime(1000), distinctUntilChanged())
        .subscribe({
          next: (wishlistItem: Wishlist) => {
            this.wishlist.push(wishlistItem);
          },
          error: () => {
            this.toastService.presentToast('An error as occurred.');
          },
        });
    }
  }

  navigateToProductDetails(id: string) {
    this.router.navigate([`/product-details`, `${id}`]);
  }

  async productSort() {
    const alert = await this.alertController.create({
      header: 'Product Sort',
      subHeader: 'Select Sort Criteria Below',
      buttons: this.sortButtons,
      inputs: this.sortOptions(),
      cssClass: 'themed-alert',
    });

    await alert.present();
  }

  async openFilterAlert() {
    const alert = await this.alertController.create({
      header: 'Filter by Category',
      buttons: this.filterButtons,
      inputs: this.filterOptions(),
      cssClass: 'themed-alert',
    });

    await alert.present();
  }
}
