import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormControl,
  FormGroup,
  FormsModule,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonList,
  IonItem,
  IonInput,
  IonInputPasswordToggle,
  IonButton,
  IonLabel,
  IonToast,
  IonProgressBar,
  IonText,
  IonAlert,
} from '@ionic/angular/standalone';
import { Router } from '@angular/router';
import { ToastService } from 'src/app/services/toast.service';
import { UserService } from 'src/app/services/user.service';
import { ChangePassword } from 'src/app/models/change-password.model';
import { PasswordMatchValidator } from 'src/app/validators/password-match.validator';
import { HttpErrorResponse } from '@angular/common/http';
import { AppService } from 'src/app/services/app.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-update-password',
  templateUrl: './update-password.page.html',
  styleUrls: ['./update-password.page.scss'],
  standalone: true,
  imports: [
    IonAlert,
    IonText,
    IonProgressBar,
    IonToast,
    IonLabel,
    IonButton,
    IonInput,
    IonItem,
    IonList,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    CommonModule,
    FormsModule,
    IonInputPasswordToggle,
    ReactiveFormsModule,
  ],
})
export class UpdatePasswordPage {
  updatePasswordForm: FormGroup = new FormGroup({
    currentPassword: new FormControl(null, Validators.required),
    newPassword: new FormControl(null, Validators.required),
    confirmNewPassword: new FormControl(null, Validators.required)
  }, [PasswordMatchValidator]);

  loading = false;
  
  public alertButtons = [
    {
      text: 'Yes',
      role: 'cancel',
      handler: () => {
        this.router.navigateByUrl('/my-profile');
      },
    },
    {
      text: 'No',
      role: 'confirm',
    },
  ];

  backSubscription?: Subscription;

  constructor(
    private router: Router,
    private toastService: ToastService,
    private userService: UserService,
    private appService: AppService,
  ) {}

  ionViewWillEnter(): void {
    this.backSubscription = this.appService.backSubject.subscribe(_ => {
      this.router.navigateByUrl('/my-profile');
    })
  }

  ionViewWillLeave(): void {
    if (this.backSubscription) {
      this.backSubscription.unsubscribe();
    }
  }

  onSave() {
    const updatePassValues: ChangePassword = this.updatePasswordForm
      .value as ChangePassword;
    this.loading = true;

    this.userService.changePassword(updatePassValues).subscribe({
      next: () => {
        this.loading = false;
        this.toastService.presentToast('Password changed successfully.');
        this.router.navigateByUrl('/home');
      },
      error: (err: HttpErrorResponse) => {
        this.loading = false;
        this.toastService.presentToast(err.error.suggestedMessage);
      },
    });
  }
}
