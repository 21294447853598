import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Post } from '../models/post.model';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Likes } from '../models/likes';

@Injectable({
  providedIn: 'root',
})
export class LikesService {
  path = '/api/Likes';

  constructor(private http: HttpClient) {}

  create(userId: string, postId: string) {
    return this.http.post<Likes>(`${environment.API}${this.path}`, {
      userId: userId,
      postId: postId,
    });
  }

  getLikesForUser(queryParams?: Map<string, any>): Observable<Likes[]> {
    const queryParametersString = this.buildQueryParameters(queryParams);
    return this.http.get<Likes[]>(
      `${environment.API}${this.path}${queryParametersString}`,
    );
  }

  delete(likeId: string) {
    return this.http.delete(`${environment.API}${this.path}/${likeId}`);
  }

  deleteLikesByPostId(postId: string) {
    return this.http.delete(
      `${environment.API}${this.path}/DeleteByPost/${postId}`,
    );
  }

  protected buildQueryParameters(queryParameters?: Map<string, any>): string {
    if (queryParameters == null || queryParameters.size == 0) {
      return '';
    }

    let queryString = '?';

    for (let entry of queryParameters.entries()) {
      if (typeof entry[1] == 'object') {
        for (let val of Array.from(entry[1])) {
          queryString += entry[0] + '=' + val + '&';
        }
      } else {
        queryString += entry[0] + '=' + queryParameters.get(entry[0]) + '&';
      }
    }

    queryString = queryString.substring(0, queryString.length - 1);

    return queryString;
  }
}
