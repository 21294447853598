import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonFab,
  IonIcon,
  IonFabButton,
  IonText,
  IonContent,
  IonCard,
  IonLabel,
  IonRippleEffect,
} from '@ionic/angular/standalone';
import { addIcons } from 'ionicons';
import {
  listOutline,
  bagOutline,
  pricetagOutline,
  personOutline,
} from 'ionicons/icons';
import { UserProfile } from 'src/app/models/user-profile.model';
import { MeService } from 'src/app/services/me.service';

@Component({
  selector: 'app-home',
  templateUrl: 'home.page.html',
  styleUrls: ['home.page.scss'],
  standalone: true,
  imports: [
    IonLabel,
    IonCard,
    IonText,
    IonFabButton,
    IonIcon,
    IonFab,
    IonGrid,
    IonRow,
    IonCol,
    IonButton,
    RouterModule,
    IonContent,
    IonRippleEffect,
  ],
})
export class HomePage implements OnInit {
  username = '';

  constructor(private meService: MeService) {
    addIcons({ listOutline, bagOutline, pricetagOutline, personOutline });
  }

  ngOnInit(): void {
    this.meService.getUserProfile().subscribe((response: UserProfile) => {
      this.username = `${response.user.firstName}`;
    });
  }
}
