<ion-content [fullscreen]="true">
  <div class="center-content">
    <ion-progress-bar type="indeterminate" *ngIf="loading"></ion-progress-bar>
    <div class="center-header-item">
      <ion-label>
        <h1>Login</h1>
        <p>Please login to continue.</p>
      </ion-label>
    </div>
    <form [formGroup]="loginForm" (ngSubmit)="onLoginClick()">
      <ion-item>
        <ion-input
          formControlName="email"
          label="Email"
          labelPlacement="stacked"
          type="email"
          placeholder="email@domain.com"
        ></ion-input>
      </ion-item>

      <ion-text
        color="danger"
        *ngIf="loginForm.get('email')?.hasError('required') && loginForm.get('email')?.touched"
      >
        Email is required.
      </ion-text>

      <ion-item>
        <ion-input
          formControlName="password"
          label="Password"
          labelPlacement="stacked"
          type="password"
          placeholder="password"
        >
          <ion-input-password-toggle slot="end"></ion-input-password-toggle>
        </ion-input>
      </ion-item>

      <ion-text
        color="danger"
        *ngIf="loginForm.get('password')?.hasError('required') && loginForm.get('password')?.touched"
      >
        Password is required.
      </ion-text>

      <ion-button type="submit" expand="full">Login</ion-button>
      <div class="flex justify-between w-full underline">
        <div routerLink="/forgot-password">Forget Password?</div>
        <div routerLink="/create-account">Sign Up</div>
      </div>
    </form>
  </div>
</ion-content>
