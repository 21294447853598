<ion-header>
  <ion-toolbar color="primary">
    <ion-title> Reason for Report </ion-title>
    
    <ion-buttons slot="end">
      <ion-button color="light" (click)="cancel()">
        <ion-icon name="close-outline"> </ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <ion-progress-bar type="indeterminate" *ngIf="loading"></ion-progress-bar>
  <ion-list>
    <ion-radio-group [formControl]="reportReason">
      <ion-item>
        <ion-radio color="secondary" value="bullying"> Bullying </ion-radio>
      </ion-item>

      <ion-item>
        <ion-radio color="secondary" value="inappropriate"> Inappropriate </ion-radio>
      </ion-item>

      <ion-item>
        <ion-radio color="secondary" value="falseInfo"> False Information </ion-radio>
      </ion-item>

      <ion-item>
        <ion-radio color="secondary" value="other"> Other Reason </ion-radio>
      </ion-item>

      <ion-item *ngIf="reportReason.value === 'other'">
        <ion-textarea
          [formControl]="otherReason"
          placeholder="Enter other reason"
        ></ion-textarea>
      </ion-item>
    </ion-radio-group>
  </ion-list>

  <ion-button [disabled]="isSubmitDisabled()" (click)="submitReport()">
    Submit Report
  </ion-button>
</ion-content>
