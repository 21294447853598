<ion-app>
  <ion-header>
    <ion-toolbar color="primary">
      <ion-buttons slot="start" style="width: 10%">
        @if (showBack) {
          <ion-button (click)="onBackClicked()">
            <ion-icon size="large" name="arrow-undo"></ion-icon>
          </ion-button>
        }
      </ion-buttons>

      <ion-title style="text-align: center">
        {{ pageTitle }}
      </ion-title>

      <ion-buttons slot="end" style="width: 10%">
        <img src="assets/Bizi logo-white letters.png" />
      </ion-buttons>
    </ion-toolbar>
  </ion-header>

  <ion-content>
    <div>
      <ion-router-outlet
        style="height: 100%"
        [animated]="false"
      ></ion-router-outlet>
    </div>
  </ion-content>

  <ion-footer>
    <ion-toolbar color="secondary">
      @if (showFooterIcons) {
        <ion-buttons slot="start">
          <ion-button routerLink="home">
            <ion-icon size="large" name="home-outline"></ion-icon>
          </ion-button>
        </ion-buttons>

        <ion-buttons slot="end">
          <ion-button routerLink="wish-list">
            <ion-icon size="large" name="heart-outline"></ion-icon>
          </ion-button>
          <ion-button routerLink="/daily-chat">
            <ion-icon
              size="large"
              name="chatbubble-ellipses-outline"
            ></ion-icon>

            @if (notifications.length && notifications.length > 0) {
              <ion-badge mode="md">1</ion-badge>
            }
          </ion-button>
        </ion-buttons>
      }
    </ion-toolbar>
  </ion-footer>
</ion-app>
