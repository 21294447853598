import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TokenModel } from '../models/user.model';
import { environment } from 'src/environments/environment';

declare const Buffer: any;

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private http: HttpClient) {}

  path = '/api/Authentication/Authenticate';

  login(username: string, password: string): Observable<any> {
    const userString = `${username}:${password}`;
    const encodedString = btoa(userString);
    return this.http.post(
      `${environment.API}${this.path}`,
      {
        portal: "app",
      },
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          Authorization: `Basic ${encodedString}`,
        }),
      },
    );
  }

  refreshToken(refreshToken: string): Observable<TokenModel> {
    return this.http.post<TokenModel>(`${environment.API}${this.path}`, {
        portal: 'app',
      }, {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        RefreshToken: refreshToken,
      },
    });
  }

  pingBackend(): Observable<boolean> {
    return this.http.get<boolean>(`${environment.API}/api/me/ping`);
  }
}
