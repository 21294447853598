<ion-content [fullscreen]="true">
  <ion-progress-bar type="indeterminate" *ngIf="loading"></ion-progress-bar>

  <ion-list lines="full">
    <ion-item class="avatar-button">
      <ion-button fill="clear" (click)="pickPhoto()">
        <img [src]="profilePicture" class="avatar-img" />
      </ion-button>
    </ion-item>
    <ion-item>
      <ion-icon aria-hidden="true" name="business" slot="start"></ion-icon>
      <ion-input
        label="College"
        labelPlacement="stacked"
        placeholder="College"
        [disabled]="true"
        value="{{collegeName}}"
      ></ion-input>
    </ion-item>

    <form [formGroup]="editProfileForm" (ngSubmit)="onSave()">
      <ion-item>
        <ion-icon aria-hidden="true" name="school" slot="start"></ion-icon>
        <ion-select
          label="Campus"
          label-placement="stacked"
          [placeholder]="this.campusName"
          formControlName="campus"
        >
          <ion-select-option *ngFor="let campus of campuses" [value]="campus">
            {{campus.name}}
          </ion-select-option>
        </ion-select>
      </ion-item>
      <ion-item>
        <ion-icon aria-hidden="true" name="at" slot="start"></ion-icon>
        <ion-input
          label="Email"
          labelPlacement="stacked"
          placeholder="Email"
          [disabled]="true"
          value="{{email}}"
        ></ion-input>
      </ion-item>
      <div>
        <ion-item>
          <ion-icon aria-hidden="true" name="home" slot="start"></ion-icon>
          <ion-input
            label="Street"
            labelPlacement="stacked"
            formControlName="street"
            placeholder="Street"
            value="{{street}}"
          ></ion-input>
        </ion-item>

        <ion-item>
          <ion-icon aria-hidden="true" slot="start"></ion-icon>
          <ion-input
            label="City"
            labelPlacement="stacked"
            formControlName="city"
            placeholder="City"
            value="{{city}}"
          ></ion-input>
          <ion-text
            color="danger"
            *ngIf="editProfileForm.get('city')?.hasError('required') && editProfileForm.get('city')?.touched"
          >
            A city is required.
          </ion-text>
        </ion-item>

        <ion-item>
          <ion-icon aria-hidden="true" slot="start"></ion-icon>
          <ion-input
            label="State"
            labelPlacement="stacked"
            formControlName="state"
            placeholder="State"
            value="{{state}}"
          ></ion-input>
          <ion-text
            color="danger"
            *ngIf="editProfileForm.get('state')?.hasError('required') && editProfileForm.get('state')?.touched"
          >
            A state is required.
          </ion-text>
        </ion-item>

        <ion-item>
          <ion-icon aria-hidden="true" slot="start"></ion-icon>
          <ion-input
            label="Zipcode"
            labelPlacement="stacked"
            formControlName="zipcode"
            placeholder="Zipcode"
            value="{{zipcode}}"
          ></ion-input>
          <ion-text
            color="danger"
            *ngIf="editProfileForm.get('zipcode')?.hasError('required') && editProfileForm.get('zipcode')?.touched"
          >
            A state is required.
          </ion-text>
        </ion-item>
      </div>

      <ion-item>
        <ion-icon aria-hidden="true" name="school" slot="start"></ion-icon>
        <ion-input
          label="Major"
          labelPlacement="stacked"
          formControlName="major"
          placeholder="Major"
          value="{{major}}"
        ></ion-input>

        <ion-text
          color="danger"
          *ngIf="editProfileForm.get('major')?.hasError('required') && editProfileForm.get('major')?.touched"
        >
          A major is required.
        </ion-text>
      </ion-item>

      <ion-item>
        <ion-icon aria-hidden="true" name="bookmark" slot="start"></ion-icon>
        <ion-textarea
          label="Bio"
          labelPlacement="stacked"
          formControlName="bio"
          placeholder="Personal Biography"
          [autoGrow]="true"
          value="{{bio}}"
        >
        </ion-textarea>
        <ion-text
          color="danger"
          *ngIf="editProfileForm.get('bio')?.hasError('required') && editProfileForm.get('bio')?.touched"
        >
          A bio is required.
        </ion-text>
      </ion-item>

      <div class="buttons">
        <ion-button
          type="submit"
          color="success"
          [disabled]="editProfileForm.invalid"
          class="success-cancel-buttons"
        >
          <ion-label> Save </ion-label>
        </ion-button>

        <ion-button
          color="danger"
          id="present-alert-edit-profile"
          class="success-cancel-buttons"
        >
          <ion-label> Cancel </ion-label>
        </ion-button>
      </div>
      <ion-alert
        trigger="present-alert-edit-profile"
        header="Are you sure you want to cancel?"
        message="Any unsaved changes will be lost."
        [buttons]="alertButtons"
      ></ion-alert>
    </form>
  </ion-list>
</ion-content>
