import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DealService {
    path = '/api/Deal';
  constructor(
    private http: HttpClient
  ) {}

  getDeals(): Observable<any> {
    return this.http.get(`${environment.API}${this.path}`);
  }
}
