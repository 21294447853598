import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonButton,
  IonIcon,
  IonItem,
  IonLabel,
  IonInput,
  IonFooter,
  IonButtons,
  IonList,
  IonProgressBar,
  IonText,
  ModalController,
} from '@ionic/angular/standalone';
import { addIcons } from 'ionicons';
import {
  chevronForwardCircleOutline,
  chevronBackCircleOutline,
  send,
  star,
  starOutline,
  heart,
  pencilOutline,
} from 'ionicons/icons';
import { Product } from 'src/app/models/product.model';
import { ToastService } from 'src/app/services/toast.service';
import { register } from 'swiper/element/bundle';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import Swiper from 'swiper';
import { ProductImages } from 'src/app/models/productImages.model';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductService } from 'src/app/services/products.service';
import { UserService } from 'src/app/services/user.service';
import { User } from 'src/app/models/user.model';
import { MeService } from 'src/app/services/me.service';
import { Wishlist } from 'src/app/models/wishlist.model';
import { WishlistService } from 'src/app/services/wishlist.service';
import { UserProfile } from 'src/app/models/user-profile.model';
import { RateService } from 'src/app/services/rate.service';
import { Rate } from 'src/app/models/rate.model';
import { EditProductModalComponent } from 'src/app/modals/edit-product-modal-component/edit-product-modal-component.component';
import { HttpErrorResponse } from '@angular/common/http';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { LoadingService } from 'src/app/services/loading.service';
import { ConversationService } from 'src/app/services/conversation.service';
import { MessageService } from 'src/app/services/message.service';
import { AppService } from 'src/app/services/app.service';
@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.page.html',
  styleUrls: ['./product-details.page.scss'],
  standalone: true,
  imports: [
    IonText,
    IonProgressBar,
    IonList,
    IonButtons,
    IonFooter,
    IonInput,
    IonLabel,
    IonItem,
    IonIcon,
    IonButton,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    CommonModule,
    FormsModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ProductDetailsPage implements OnInit, OnDestroy {
  seller?: User;
  user?: UserProfile;
  item?: Product;
  wishlist?: Wishlist[];
  id!: string;
  userRating?: Rate;

  @ViewChild('swiper')
  swiperRef: ElementRef | undefined;

  swiperDoc = document.getElementById('#thumbsSwiper');

  swiper?: Swiper;
  loading: boolean = false;
  stars: { name: string; num: number }[] = [
    { name: 'star-outline', num: 1 },
    { name: 'star-outline', num: 2 },
    { name: 'star-outline', num: 3 },
    { name: 'star-outline', num: 4 },
    { name: 'star-outline', num: 5 },
  ];

  messageSeller: string = '';

  backSubscription?: Subscription;

  constructor(
    private toastService: ToastService,
    private activatedRoute: ActivatedRoute,
    private productService: ProductService,
    private userService: UserService,
    private meService: MeService,
    private wishlistService: WishlistService,
    private rateService: RateService,
    private modalController: ModalController,
    private loadingService: LoadingService,
    private conversationService: ConversationService,
    private RouterService: Router,
    private messageService: MessageService,
    private router: Router,
    private appService: AppService,
  ) {
    addIcons({
      pencilOutline,
      send,
      chevronBackCircleOutline,
      chevronForwardCircleOutline,
      starOutline,
      star,
      heart,
    });
    register();
  }

  ngOnInit(): void {
    this.backSubscription = this.appService.backSubject.subscribe((_) => {
      this.router.navigateByUrl('/shop-products');
    });

    var queryParams: Map<string, any> = new Map([['RatedUser', '']]);
    this.meService.getUserProfile().subscribe((user) => (this.user = user));
    this.activatedRoute.paramMap.subscribe((data) => {
      this.productService
        .getProduct(data.get('id') ?? '')
        .subscribe((product) => {
          queryParams.set('RatedUser', product.createdById);
          this.item = product;

          this.rateService
            .getRatings(queryParams)
            .subscribe((ratings: number) => {
              this.handleRating(ratings);
            });

          this.userService
            .getUser(product.createdById!)
            .subscribe((user) => (this.seller = user));
          this.meService.getWishListItems().subscribe((wishlist) => {
            this.wishlist = wishlist;

            wishlist.forEach((wishlistItem: Wishlist) => {
              if (product.id == wishlistItem.productId) {
                product.onWishlist = true;
              }
            });
          });
        });
    });
  }

  ngOnDestroy(): void {
    if (this.backSubscription) {
      this.backSubscription.unsubscribe();
    }
  }

  onThumbClick(picture: ProductImages) {
    const index = this.item?.images?.indexOf(picture);
    this.swiperRef?.nativeElement.swiper.slideTo(index);
  }

  heartButtonClick(item: Product) {
    item.onWishlist = !item.onWishlist;
    let wishlistItem: Wishlist | undefined = this.wishlist?.find(
      (wishlistItem) => wishlistItem.productId == item.id,
    );
    if (wishlistItem != undefined) {
      this.wishlistService.removeWishlistItem(wishlistItem).subscribe();
    } else {
      this.wishlistService
        .addWishlistItem(item.id!, this.user?.userId ?? '')
        .subscribe();
    }

    this.meService
      .getWishListItems()
      .subscribe((response: Wishlist[]) => (this.wishlist = response));
  }

  updateSellerRating(num: number) {
    var body: Rate = {
      id: this.userRating?.id ?? '',
      rating: num,
      ratedUser: this.seller?.id ?? '',
    };
    if (this.userRating == null) {
      this.rateService.rateAUser(body).subscribe();
    } else {
      this.rateService.updateRating(body).subscribe({
        next: () => {
          this.handleRating(num);
        },

        error: (err: HttpErrorResponse) => {
          this.loading = false;
          this.toastService.presentToast(err.error);
        },
      });
    }
  }

  handleRating(num: number): void {
    this.stars = [];
    for (let i = 1; i <= 5; i++) {
      if (i <= num) {
        this.stars.push({ name: 'star', num: i });
      } else {
        this.stars.push({ name: 'star-outline', num: i });
      }
    }
  }

  async editModal(item: Product) {
    const modal = await this.modalController.create({
      component: EditProductModalComponent,
      componentProps: { product: item },
    });

    modal.present();

    const { data, role } = await modal.onWillDismiss();

    if (role === 'confirm') {
      var product: Product = {
        id: this.item?.id,
        title: data.controls['name'].value,
        price: data.controls['price'].value,
        description: data.controls['description'].value,
        categoryId: data.controls['categoryId'].value,
        status: 'ACTIVE',
      };

      var requests: Observable<any>[] = [];

      requests.push(this.productService.updateProduct(product));
      if (data.controls['imagesToAdd'].length > 0) {
        requests.push(
          this.productService.uploadProductImages(
            this.item?.id ?? '',
            data.controls['imagesToAdd'],
          ),
        );
      }

      if (data.controls['imagesToRemove'].value.length > 0) {
        data.controls['imagesToRemove'].value.forEach(
          (image: ProductImages) => {
            var queryParams: Map<string, any> = new Map([
              ['fileName', image.fileName],
            ]);

            requests.push(
              this.productService.deleteProductImage(
                this.item?.id ?? '',
                queryParams,
              ),
            );
          },
        );
      }

      this.loadingService.showLoading(
        'Image(s) are being uploaded please wait...',
      );

      forkJoin(requests).subscribe(() => {
        this.productService
          .getProduct(product.id ?? '')
          .subscribe((product) => {
            this.item = product;
            this.loadingService.hideLoading();
          });
      });
    }
  }

  ratingDisabled() {
    return false;
  }

  sendMessage() {
    if (this.seller) {
      this.conversationService
        .createConversation(this.seller?.id)
        .subscribe((conversation) => {
          this.messageService
            .createMessage(conversation.id, this.messageSeller)
            .subscribe((message) => {
              this.RouterService.navigate(['/chat', conversation.id]);
            });
        });
    }
  }
}
