import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserProfile } from '../models/user-profile.model';
import { Rate } from '../models/rate.model';
import { Conversation } from '../models/chat.model';
import { MyChats } from '../models/mychats.model';

@Injectable({
  providedIn: 'root',
})
export class MeService {
  constructor(private http: HttpClient) {}

  path = '/api/Me';

  getUserProfile(): Observable<UserProfile> {
    return this.http.get<UserProfile>(`${environment.API}${this.path}/Profile`);
  }

  updateUserProfile(data: UserProfile): Observable<any> {
    return this.http.put(`${environment.API}${this.path}/Profile`, data);
  }

  getWishListItems(): Observable<any> {
    return this.http.get(`${environment.API}${this.path}/Wishlist`);
  }

  getMyRating(): Observable<number> {
    return this.http.get<number>(`${environment.API}${this.path}/MyRating`);
  }

  getRatings(queryParams?: Map<string, any>): Observable<Rate[]> {
    const queryParametersString = this.buildQueryParameters(queryParams);
    return this.http.get<Rate[]>(
      `${environment.API}${this.path}/Ratings/${queryParametersString}`,
    );
  }

  getMyChats(): Observable<MyChats[]> {
    return this.http.get<MyChats[]>(`${environment.API}${this.path}/MyChats`);
  }

  protected buildQueryParameters(queryParameters?: Map<string, any>): string {
    if (queryParameters == null || queryParameters.size == 0) {
      return '';
    }

    let queryString = '?';

    for (let entry of queryParameters.entries()) {
      if (typeof entry[1] == 'object') {
        for (let val of Array.from(entry[1])) {
          queryString += entry[0] + '=' + val + '&';
        }

        queryString = queryString.substring(0, queryString.length - 1);
      } else {
        queryString += entry[0] + '=' + queryParameters.get(entry[0]);
      }
    }

    return queryString;
  }
}
