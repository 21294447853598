<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-button color="light" (click)="cancel()">Cancel</ion-button>
    </ion-buttons>

    <ion-title class="text-center" color="light">Create A Listing</ion-title>
    
    <ion-buttons slot="end">
      <ion-button color="light" (click)="canDismiss()" [strong]="true">Confirm</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content [formGroup]="createItemForm" class="ion-padding">
  <ion-item>
    <ion-input
      formControlName="name"
      labelPlacement="stacked"
      label="Name"
    ></ion-input>
    <ion-text
      color="danger"
      *ngIf="
        createItemForm.get('name')?.hasError('required') &&
        createItemForm.get('name')?.touched
      "
    >
      A name is required.
    </ion-text>
  </ion-item>
  <ion-item>
    <ion-input
      type="number"
      formControlName="price"
      labelPlacement="stacked"
      label="Price"
    ></ion-input>
    <ion-text
      color="danger"
      *ngIf="
        createItemForm.get('price')?.hasError('required') &&
        createItemForm.get('price')?.touched
      "
    >
      A price is required.
    </ion-text>
  </ion-item>
  <ion-item>
    <ion-input
      formControlName="description"
      labelPlacement="stacked"
      label="Description"
    ></ion-input>
    <ion-text
      color="danger"
      *ngIf="
        createItemForm.get('description')?.hasError('required') &&
        createItemForm.get('description')?.touched
      "
    >
      A description is required.
    </ion-text>
  </ion-item>
  <ion-item>
    <ion-select
      interface="popover"
      label="Category"
      placeholder="Category"
      formControlName="categoryId"
    >
      <ion-select-option
        *ngFor="let category of categories"
        [value]="category.id"
      >
        {{ category.name }}
      </ion-select-option>
    </ion-select>
    <ion-text
      color="danger"
      *ngIf="
        createItemForm.get('category')?.hasError('required') &&
        createItemForm.get('category')?.touched
      "
    >
      A category is required.
    </ion-text>
  </ion-item>

  <input
    type="file"
    class="file-input"
    (change)="onFileSelected($event)"
    #fileUpload
    multiple
  />

  <div class="flex justify-center mt-2">
    <ion-button color="primary" (click)="fileUpload.click()">
      <ion-label color="light">Upload</ion-label>
    </ion-button>
  </div>

  <ion-grid>
    <ion-row>
      <ion-col
        class="flex items-start justify-center"
        *ngFor="let img of imgUrls"
        size="6"
      >
        <ion-card class="w-fit">
          <div class="w-full flex justify-center relative">
            <img class="flex min-h-12 max-h-28" src="{{ img }}" />
            <div class="absolute top-0 right-0">
              <ion-button
                (click)="deleteImg(img)"
                size="small"
                shape="round"
                fill="clear"
                ><ion-icon
                  color="danger"
                  size="large"
                  slot="icon-only"
                  name="close-circle-outline"
                ></ion-icon
              ></ion-button>
            </div>
          </div>
        </ion-card>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
