import { Component, OnInit, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonCard,
  IonCardSubtitle,
  IonCardHeader,
  IonCardContent,
  IonCardTitle,
  IonButton,
  IonItem,
  IonInput,
  IonSelect,
  IonSelectOption,
  IonLabel,
  IonText,
  IonInputPasswordToggle,
  IonProgressBar,
} from '@ionic/angular/standalone';
import { WhiteSpaceCheckValidator } from 'src/app/validators/white-space-check.validator';
import { EduEmailValidator } from 'src/app/validators/edu-email.validator';
import { UserService } from 'src/app/services/user.service';
import { UserVerificationService } from 'src/app/services/user-verification.service';
import { User } from 'src/app/models/user.model';
import { VerifyAccount } from 'src/app/models/verify-account.model';
import { Router } from '@angular/router';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-verification',
  templateUrl: './verification.page.html',
  styleUrls: ['./verification.page.scss'],
  standalone: true,
  imports: [
    IonCardContent,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    CommonModule,
    FormsModule,
    IonInput,
    IonProgressBar,
    IonText,
    IonLabel,
    IonInput,
    IonItem,
    IonButton,
    IonCardTitle,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCard,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonSelect,
    IonSelectOption,
    IonInputPasswordToggle,
  ],
})
export class VerificationPage {
  loading: boolean = false;

  verificationForm = new FormGroup({
    role: new FormControl(2),
    email: new FormControl(this.userVerificationService.userEmail, [
      Validators.required,
      Validators.email,
      WhiteSpaceCheckValidator,
      EduEmailValidator,
    ]),
    verificationCode: new FormControl('', [
      Validators.required,
      WhiteSpaceCheckValidator,
    ]),
  });

  constructor(
    private userVerificationService: UserVerificationService,
    private userService: UserService,
    private router: Router,
    private toastService: ToastService,
  ) {}

  onVerify() {
    const verificationFormValues: VerifyAccount = this.verificationForm
      .value as VerifyAccount;

    this.userService.verifyAccount(verificationFormValues).subscribe({
      next: (response) => {
        this.userVerificationService.deleteVerification(response).subscribe({
          next: () => {
            this.router.navigateByUrl('/home');
          },
        });
      },
      error: (response) => {
        this.toastService.presentToast(response.error.suggestedMessage, 4000);
      },
    });
  }

  onResend() {
    this.userVerificationService
      .updateVerificationCode(this.verificationForm.controls.email.value!)
      .subscribe({
        next: () => {
          this.toastService.presentToast(
            'A new verification code was sent to your email address.',
            3000,
          );
        },
        error: () => {
          this.toastService.presentToast(
            'A new verification code could not be sent to your email address. Please try again.',
            3000,
          );
        },
      });
  }
}
