import {
  Component,
  computed,
  Signal,
  signal,
  WritableSignal,
} from '@angular/core';
import {
  FormsModule,
} from '@angular/forms';
import { Router } from '@angular/router';
import {
  IonContent,
  IonHeader,
  IonToolbar,
  IonList,
  IonTitle,
  IonButtons,
  IonButton,
  ModalController,
  IonSearchbar,
  IonItem,
  IonText,
  IonLabel,
  IonIcon,
} from '@ionic/angular/standalone';
import { addIcons } from 'ionicons';
import {
  addOutline,
  trashOutline,
} from 'ionicons/icons';
import { User } from 'src/app/models/user.model';
import { ConversationService } from 'src/app/services/conversation.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-group-chat-modal',
  templateUrl: './group-chat-modal.component.html',
  styleUrls: ['./group-chat-modal.component.scss'],
  standalone: true,
  imports: [
    IonIcon,
    IonLabel,
    IonText,
    IonItem,
    IonContent,
    IonHeader,
    IonToolbar,
    IonList,
    IonTitle,
    IonButtons,
    IonButton,
    IonSearchbar,
    FormsModule,
  ],
})
export class GroupChatModalComponent {
  options: WritableSignal<User[]> = signal([]);
  selectedUsers: WritableSignal<User[]> = signal([]);

  searchValue: string = '';

  availibleOptions: Signal<User[]> = computed(() => {
    const opts = [...this.options()];
    const selected = this.selectedUsers();

    for (const sel of selected) {
      const index = opts.findIndex((u) => u.id == sel.id);

      if (index >= 0) {
        opts.splice(index, 1);
      }
    }

    return opts;
  });

  constructor(
    private modalController: ModalController,
    private userService: UserService,
    private conversationService: ConversationService,
    private router: Router,
  ) {
    addIcons({
      addOutline,
      trashOutline,
    });
  }

  search(term: string) {
    this.userService.search(term).subscribe((users) => {
      this.options.set(users);
    });
  }

  cancel() {
    this.modalController.dismiss();
  }

  save() {
    const selected = [...this.selectedUsers()];
    const userIds = selected.map(s => s.id);

    this.conversationService.createGroupChat(userIds).subscribe({
      next: convo => {
        this.router.navigateByUrl(`/chat/${convo.id}`);
        this.modalController.dismiss();
      },
      error: err => {
        console.error(err);
      }
    });
  }

  handleSearch(event: CustomEvent) {
    if (this.searchValue.length > 1) {
      this.search(this.searchValue);
    } else {
      this.options.set([]);
    }
  }

  userSelected(user: User) {
    this.searchValue = '';
    this.options.set([]);

    let value = [...this.selectedUsers()];
    value.push(user);

    this.selectedUsers.set(value);
  }

  userRemoved(user: User) {
    let value = [...this.selectedUsers()];
    const foundIndex = value.findIndex((u) => u.id == user.id);

    if (foundIndex > -1) {
      value.splice(foundIndex, 1);
      this.selectedUsers.set(value);
    }
  }
}
