<ion-content class="height">
  @if(userProfile) {
  <ion-list lines="full">
    <ion-item>
      <div class="profile-image-container">
        <img [src]="this.userProfile.profilePictureURL ?? defaultProfilePicURL"/>
      </div>
    </ion-item>
    <ion-item>
      <ion-icon aria-hidden="true" name="business" slot="start"></ion-icon>
      @if(userProfile.campus) {
      <ion-label> {{userProfile.campus.college.name}} </ion-label>
      }
    </ion-item>

    <ion-item>
      <ion-icon aria-hidden="true" name="school" slot="start"></ion-icon>
      @if(userProfile.campus) {
      <ion-label> {{userProfile.campus.name}} </ion-label>
      }
    </ion-item>

    <ion-item>
      <ion-icon aria-hidden="true" name="at" slot="start"></ion-icon>
      @if(userProfile.user) {
      <ion-label> {{userProfile.user.email}} </ion-label>
      }
    </ion-item>

    <ion-item>
      <ion-icon aria-hidden="true" name="school" slot="start"></ion-icon>
      <ion-label> {{userProfile.major}} </ion-label>
    </ion-item>

    <ion-item>
      <ion-icon aria-hidden="true" name="person-circle" slot="start"></ion-icon>
      <ion-icon
        *ngFor="let star of stars"
        [name]="star"
        class="rating"
      ></ion-icon>
    </ion-item>

    <ion-item>
      <ion-icon aria-hidden="true" name="bookmark" slot="start"></ion-icon>
      <ion-textarea
        [readonly]="true"
        placeholder="Personal Bio"
        [autoGrow]="true"
        value="{{userProfile.bio}}"
      >
      </ion-textarea>
    </ion-item>
  </ion-list>
  }
  <div>
    <!-- <ion-button
      shape="round"
      class="testing"
      (click)="toggleTheme = !toggleTheme"
    >
      <ion-label>
        {{toggleTheme ? 'Toggle Light Theme' : 'Toggle Dark Theme'}}
      </ion-label>
    </ion-button> -->

    <ion-button shape="round" routerLink="/edit-profile">
      <ion-label> Edit Profile Info </ion-label>
    </ion-button>

    <ion-button shape="round" routerLink="/update-password">
      <ion-label> Update Password </ion-label>
    </ion-button>

    <ion-button shape="round" color="danger" id="present-alert-delete-account">
      <ion-label> Delete Account </ion-label>
    </ion-button>

    <ion-button fill="outline" color="danger" shape="round" (click)="logout()">
      <ion-label>Logout</ion-label>
    </ion-button>

    <ion-alert
      trigger="present-alert-delete-account"
      header="Are you sure you want to delete your account?"
      subHeader="This action cannot be undone."
      [buttons]="alertButtons"
    ></ion-alert>
  </div>
</ion-content>
