import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Post } from '../models/post.model';
import { PickedFile } from '@capawesome/capacitor-file-picker';

@Injectable({
  providedIn: 'root',
})
export class PostService {
  path = '/api/Post';
  fileUploadPath = "/api/Storage/"

  constructor(private http: HttpClient) {}

  getPosts(queryParams?: Map<string, any>): Observable<Post[]> {
    const queryParamString = this.buildQueryParameters(queryParams);
    return this.http.get<Post[]>(
      `${environment.API}${this.path}/${queryParamString}`,
    );
  }

  createPost(body: Post): Observable<Post> {
    return this.http.post<Post>(`${environment.API}${this.path}`, body);
  }

  deletePost(postId: string) {
    return this.http.delete(
      `${environment.API}${this.path}/${postId}`
    );
  }

  protected buildQueryParameters(queryParameters?: Map<string, any>): string {
    if (queryParameters == null || queryParameters.size == 0) {
      return '';
    }

    let queryString = '?';

    for (let entry of queryParameters.entries()) {
      if (typeof entry[1] == 'object') {
        for (let val of Array.from(entry[1])) {
          queryString += entry[0] + '=' + val + '&';
        }

        queryString = queryString.substring(0, queryString.length - 1);
      } else {
        queryString += entry[0] + '=' + queryParameters.get(entry[0]);
      }
    }

    return queryString;
  }
  
  uploadPostFile(postId: string, file: PickedFile) {
    var fileObj = new File([file.blob!], file.name);
    const formData = new FormData();

    formData.append(file.name, fileObj);
    formData.append('postId', postId);

    return this.http.post<Post>(`${environment.API}${this.fileUploadPath}Post`, formData);
  }
  
  deletePostFile(postId: string) {
    return this.http.delete<Comment>(
      `${environment.API}${this.fileUploadPath}Post/${postId}`
    );
  }

  report(id: string, reason: string) {
    return this.http.post(
      `${environment.API}${this.path}/Report`, {
        id,
        reason
      }
    )
  }
}
