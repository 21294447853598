import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormGroup,
  Validators,
  ReactiveFormsModule,
  FormControl,
} from '@angular/forms';
import {
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonItem,
  IonInput,
  IonButton,
  IonText,
  IonInputPasswordToggle,
  IonLabel,
  IonProgressBar,
  IonToast,
} from '@ionic/angular/standalone';
import { Login } from '../../models/login';
import { AuthService } from '../../services/auth.service';
import { Router, RouterModule } from '@angular/router';
import { TokenService } from 'src/app/services/token.service';
import { ToastService } from 'src/app/services/toast.service';
import { UserVerificationService } from 'src/app/services/user-verification.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
  standalone: true,
  imports: [
    IonToast,
    IonProgressBar,
    IonLabel,
    IonText,
    IonButton,
    IonInput,
    IonItem,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    CommonModule,
    ReactiveFormsModule,
    IonInputPasswordToggle,
    RouterModule,
  ],
})
export class LoginPage {
  loginForm: FormGroup = new FormGroup({
    email: new FormControl(null, [Validators.required, Validators.email]),
    password: new FormControl(null, Validators.required),
  });
  loading = false;

  constructor(
    private authService: AuthService,
    private router: Router,
    private tokenService: TokenService,
    private toastService: ToastService,
    private userVerificationService: UserVerificationService,
  ) {}

  ionViewWillEnter() {
    if (this.tokenService.authToken != "") {
      this.authService.pingBackend().subscribe({
        next: (isLoggedIn) => {
          if (isLoggedIn) {
            this.router.navigateByUrl('/home');
          } else {
            this.tokenService.logout();
          }
        },
        error: (err: any) => {
          this.tokenService.logout();
        },
      });
    }
  }
  
  onLoginClick(): void {
    this.loading = true;

    const loginValues: Login = this.loginForm.value as Login;

    this.authService.login(loginValues.email, loginValues.password).subscribe({
      next: (response) => {
        this.tokenService.updateTokens(response);
        this.loading = false;

        if (response.accountStatus == 'ACTIVE') {
          this.router.navigateByUrl('/home');
        } else if (response.accountStatus == 'REGISTERED') {
          this.userVerificationService.userEmail = loginValues.email;
          this.router.navigateByUrl('/verification');
        }
      },
      error: (response) => {
        this.loading = false;
        this.toastService.presentToast(response.error.suggestedMessage, 3000);
      },
    });
  }
}
