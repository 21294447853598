import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Message } from '../models/chat.model';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  path = '/api/Message';

  constructor(private http: HttpClient) {}

  getMessagesByConversation(conversationId: string): Observable<Message[]> {
    return this.http.get<Message[]>(
      `${environment.API}${this.path}/ByConversation/${conversationId}`,
    );
  }

  createMessage(conversationId: string, messageBody: string) {
    return this.http.post<Message>(`${environment.API}${this.path}`, {
      body: messageBody,
      isReported: false,
      isDeleted: false,
      outgoing: true,
      conversationId: conversationId,
    });
  }
}
