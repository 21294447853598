export enum RoleEnum {
  Admin = 0,
  Vendor =  1,
  Student = 2,
}

export interface RegisterUser {
  userInfo: {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
    status: string;
  };
  userProfileInfo: {
    phone: string;
    major: string;
    campusId?: string;
    bio?: string;
    street?: string;
    city?: string;
    state?: string;
    zipCode?: string;
    theme?: string;
  };
  role: RoleEnum;
}
