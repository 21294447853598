import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Campus } from '../models/campus.model';

@Injectable({
  providedIn: 'root',
})
export class CampusService {
  updatableProperties: string[] = ['status'];

  path = '/api/Campus';

  constructor(private http: HttpClient) {}

  getCampuses(queryParams?: Map<string, any>): Observable<Campus[]> {
    let queryParamsString = this.buildQueryParameters(queryParams);

    return this.http.get<Campus[]>(
      `${environment.API}${this.path}/GetForNewUser${queryParamsString}`,
    );
  }

  getCampusByCollegeId(collegeId: string) {
    return this.http.get(
      `${environment.API}${this.path}/Campuses/${collegeId}`,
    );
  }

  protected buildQueryParameters(queryParameters?: Map<string, any>): string {
    if (queryParameters == null || queryParameters.size == 0) {
      return '';
    }

    let queryString = '?';

    for (let entry of queryParameters.entries()) {
      if (typeof entry[1] == 'object') {
        for (let val of Array.from(entry[1])) {
          queryString += entry[0] + '=' + val + '&';
        }

        queryString = queryString.substring(0, queryString.length - 1);
      } else {
        queryString += entry[0] + '=' + queryParameters.get(entry[0]);
      }
    }

    return queryString;
  }
}
