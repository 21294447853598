<ion-content [fullscreen]="true">
  <div *ngIf="myWishlist.length === 0" lines="none" class="empty-wl-div">
    <ion-icon name="list-outline" class="empty-list-icon"></ion-icon>

    <ion-label class="empty-wl-label">
      <h2>You have no items in your wishlist.</h2>
    </ion-label>
  </div>

  <ion-list *ngFor="let item of myWishlist">
    <ion-item class="max-h-16" *ngIf="!itemsToRemove.includes(item)">
      <ion-button
        fill="clear"
        size="large"
        shape="round"
        (click)="heartClick(item)"
      >
        <ion-icon name="heart" slot="icon-only" color="secondary"></ion-icon
      ></ion-button>

      @if (item.product.images && item.product.images.length > 0) {
        <img class="mr-2 h-16" src="{{item.product.images[0].url}}" />
      }

      <ion-label>
        <h2>{{item.product.title}}</h2>
        <div class="price-sold-text">
          <p>${{item.product.price}}</p>
          <ion-label *ngIf="item.product.status === 'Inactive'" color="danger">
            Sold/Unavailable
          </ion-label>
        </div>
      </ion-label>
    </ion-item>

    <ion-item *ngIf="itemsToRemove.includes(item)">
      <ion-button
        fill="clear"
        size="large"
        shape="round"
        (click)="heartClick(item)"
      >
        <ion-icon
          name="heart-outline"
          slot="icon-only"
          color="secondary"
        ></ion-icon
      ></ion-button>

      @if (item.product.images && item.product.images.length > 0) {
        <img class="mr-2 h-16" src="{{item.product.images[0].url}}" />
      }

      <ion-label>
        <h2>{{item.product.title}}</h2>
        <div class="price-sold-text">
          <p>${{item.product.price}}</p>
          <ion-label *ngIf="item.product.status === 'Inactive'" color="danger">
            Sold/Unavailable
          </ion-label>
        </div>
      </ion-label>
    </ion-item>
  </ion-list>
</ion-content>
