<ion-content [fullscreen]="true">
  <h5>Browse jobs near campus!</h5>
  <ion-card *ngFor="let item of jobOpportunities" class="flex-container">
    <div>
      <ion-card-header>
        <ion-card-title>{{item.title}}</ion-card-title>
        <ion-card-subtitle>{{item.businessName}}</ion-card-subtitle>
      </ion-card-header>
      <ion-card-content>
        <p>{{item.street}}, {{item.city}}, {{item.state}}</p>
        <p>${{item.amount}}/hr • {{item.employmentType}}</p>
      </ion-card-content>
    </div>

    <ion-button fill="clear">
      <ion-icon name="mail-outline" class="mail-size"></ion-icon>
    </ion-button>
  </ion-card>
</ion-content>
