<ion-content>
  <ion-list class="overflow-hidden" lines="none" *ngFor="let chat of chats">
    <ion-item-sliding>
      <ion-item
        [ngClass]="{'chat-bubble-incoming-item': !chat.outgoing, 'chat-bubble-outgoing-item': chat.outgoing}"
        [color]="chat.outgoing ? 'secondary' : 'light'"
      >
        <ion-label
          background-color="secondary"
          [ngClass]="{'chat-bubble-incoming-label': !chat.outgoing, 'chat-bubble-outgoing-label': chat.outgoing}"
        >
          {{chat.body}}
        </ion-label>
      </ion-item>

      <ion-item-options [side]="chat.outgoing ? 'end' : 'start' ">
        <ion-item-option> {{chat.createdDate | date:'short'}} </ion-item-option>
        @if(chat.id) {
        <ion-item-option
          expandable="true"
          slot="end"
          [id]="'open-chat-action-sheet-' + chat.id"
          class="delete-msg"
          (click)="deleteMsg(chat.id)"
        >
          <ion-icon slot="icon-only" name="trash"></ion-icon>
        </ion-item-option>
        }
      </ion-item-options>
    </ion-item-sliding>
  </ion-list>
</ion-content>

<ion-footer class="input-area bg-white">
  <ion-input
    class="mt-1"
    fill="outline"
    placeholder="Say something.."
    [(ngModel)]="message"
  ></ion-input>

  <ion-button fill="clear" (click)="send()">
    <ion-icon name="send"> </ion-icon
  ></ion-button>
</ion-footer>
