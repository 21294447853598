import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonList,
  IonItem,
  IonLabel,
  IonIcon,
  IonItemSliding,
  IonItemOptions,
  IonItemOption,
  IonActionSheet,
  IonButton,
  ActionSheetController,
  IonFabButton,
  IonFab,
  ModalController,
} from '@ionic/angular/standalone';
import { addIcons } from 'ionicons';
import { ellipse, trash, listOutline, mailOutline } from 'ionicons/icons';
import {
  ChatSummary,
  ConversationUser,
} from 'src/app/models/chat.model';
import { ChatPage } from '../chat/chat.page';
import { Router, RouterModule } from '@angular/router';
import { ChatsService } from 'src/app/services/chats.service';
import { MeService } from 'src/app/services/me.service';
import { ConversationService } from 'src/app/services/conversation.service';
import { MyChats } from 'src/app/models/mychats.model';
import { ConversationUserService } from 'src/app/services/conversastion-user.service';
import { Subscription } from 'rxjs';
import { AppService } from 'src/app/services/app.service';
import { GroupChatModalComponent } from 'src/app/modals/group-chat-modal/group-chat-modal.component';

@Component({
  selector: 'app-my-chats',
  templateUrl: './my-chats.page.html',
  styleUrls: ['./my-chats.page.scss'],
  standalone: true,
  imports: [
    IonFab,
    IonFabButton,
    IonButton,
    IonActionSheet,
    IonItemOption,
    IonItemOptions,
    IonItemSliding,
    IonIcon,
    IonLabel,
    IonItem,
    IonList,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    CommonModule,
    FormsModule,
    ChatPage,
    RouterModule,
  ],
})
export class MyChatsPage {
  backSubscription?: Subscription;

  chats!: MyChats[];

  async presentActionSheet(myConversationUser: ConversationUser) {
    const actionSheet = await this.actionSheetController.create({
      header: 'Are you sure you want to delete this conversation?',
      buttons: [
        {
          text: 'Delete',
          role: 'destructive',
          data: {
            myConversationUser: myConversationUser,
            action: 'delete',
          },
        },
        {
          text: 'Cancel',
          role: 'cancel',
          data: {
            action: 'cancel',
          },
        },
      ],
    });

    await actionSheet.present();

    const { data, role } = await actionSheet.onWillDismiss();
    if (role == 'destructive') {
      this.deleteChat(data.myConversationUser);
    }
  }

  viewChat(chat: ChatSummary) {
    if (chat.status === 'unread') {
      chat.status = 'read';
      // Call backend to get the updated status for this chat
    }
  }

  constructor(
    private actionSheetController: ActionSheetController,
    private chatsService: ChatsService,
    private conversationService: ConversationService,
    private conversationUserService: ConversationUserService,
    private meService: MeService,
    private routerService: Router,
    private router: Router,
    private appService: AppService,
    private modalController: ModalController,
  ) {
    addIcons({ mailOutline, listOutline, ellipse, trash });
  }

  ionViewWillEnter(): void {
    this.getLatestChats();

    if (!this.backSubscription) {
      this.backSubscription = this.appService.backSubject.subscribe((_) => {
        this.router.navigateByUrl('/daily-chat');
      });
    }
  }

  ionViewDidLeave(): void {
    if (this.backSubscription) {
      this.backSubscription.unsubscribe();
      this.backSubscription = undefined;
    }
  }

  getLatestChats() {
    this.meService.getMyChats().subscribe((response) => {
      this.chats = response;
    });
  }

  deleteChat(myConversationUser: ConversationUser) {
    myConversationUser.status = 'DELETED';
    this.conversationUserService
      .updateConversationUser(myConversationUser)
      .subscribe();
  }

  navigateToChat(id: string) {
    this.routerService.navigate([`/chat`, `${id}`]);
  }

  async openSearch() {
    const modal = await this.modalController.create({
      component: GroupChatModalComponent,
    });

    await modal.present();
  }
}
