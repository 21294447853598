import { DefaultHttpClient, HttpRequest, HttpResponse, ILogger } from "@microsoft/signalr";
import { TokenModel } from "../models/user.model";

export class CustomHttpClient extends DefaultHttpClient {
  private _authToken: string;

  constructor(logger: ILogger, authToken: string) {
    super(logger);

    this._authToken = authToken;
  }

  public override send(request: HttpRequest): Promise<HttpResponse> {
    request.headers = { ...request.headers, "Authorization": `Bearer ${this._authToken}` };
    return super.send(request);
  }
}
