import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  public pageTitle: Subject<string> = new Subject();
  public backSubject: Subject<boolean> = new Subject();

  setPageTitle(title: string) {
    this.pageTitle.next(title);
  }
}
