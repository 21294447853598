import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const PasswordMatchValidator: ValidatorFn = (
  control: AbstractControl,
): ValidationErrors | null => {
  const password = control.get('newPassword');
  const confirmPassword = control.get('confirmNewPassword');

  const hasNumber = /\d/.test(password?.value);
  const hasUpperLetter = /[A-Z]/.test(password?.value);
  const hasLowerLetter = /[a-z]/.test(password?.value);
  if (
    !hasNumber ||
    !hasUpperLetter ||
    !hasLowerLetter ||
    password?.value.length < 8
  ) {
    password?.setErrors({ requirementsNotMet: true });
    return { requirementsNotMet: true };
  }

  if (
    password?.value != null &&
    confirmPassword?.value != null &&
    password.value !== confirmPassword.value
  ) {
    password?.setErrors({ passwordMatch: true });
    confirmPassword?.setErrors({ passwordMatch: true });

    return { passwordMatch: true };
  } else {
    delete password?.errors?.passwordMatch;
    delete confirmPassword?.errors?.passwordMatch;

    if (password?.errors) {
      if (Object.keys(password?.errors).length === 0) {
        password?.setErrors(null);
      }
    }

    if (confirmPassword?.errors) {
      if (Object.keys(confirmPassword?.errors).length === 0) {
        confirmPassword?.setErrors(null);
      }
    }

    return null;
  }
};
