import { Component, Input, OnInit } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  ModalController,
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonButton,
  IonModal,
  IonButtons,
  IonItem,
  IonText,
  IonSelect,
  IonSelectOption,
  IonInput,
  IonGrid,
  IonCol,
  IonRow,
  IonCard,
  IonIcon, IonLabel } from '@ionic/angular/standalone';
import { CommonModule } from '@angular/common';
import { Product } from 'src/app/models/product.model';

import { CategoryService } from 'src/app/services/category.service';
import { Category } from 'src/app/models/category.model';
import { ProductImages } from 'src/app/models/productImages.model';
import { addIcons } from 'ionicons';
import { arrowUpOutline, closeCircleOutline } from 'ionicons/icons';

@Component({
  selector: 'app-edit-product-modal-component',
  templateUrl: './edit-product-modal-component.component.html',
  styleUrls: ['./edit-product-modal-component.component.scss'],
  imports: [IonLabel, 
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonButton,
    IonModal,
    IonButtons,
    IonItem,
    IonText,
    IonSelect,
    IonSelectOption,
    IonInput,
    IonGrid,
    IonCol,
    IonRow,
    IonCard,
    IonIcon,
  ],
  standalone: true,
})
export class EditProductModalComponent implements OnInit {
  @Input() product!: Product;
  updatedProduct = { ...this.product };
  imagesToRemove: ProductImages[] = [];
  imagesToAdd: File[] = [];
  editItemForm!: FormGroup;
  categories: Category[] = [];
  imgUrls: File[] = [];

  constructor(
    private modalController: ModalController,
    private categoryService: CategoryService,
  ) {
    addIcons({ arrowUpOutline, closeCircleOutline });
  }

  ngOnInit() {
    this.updatedProduct = { ...this.product };
    this.categoryService
      .getCategories()
      .subscribe((response: Category[]) => (this.categories = response));

    this.editItemForm = new FormGroup({
      name: new FormControl(this.product?.title, [Validators.required]),
      price: new FormControl(this.product?.price, [Validators.required]),
      categoryId: new FormControl(this.product?.categoryId, [
        Validators.required,
      ]),
      description: new FormControl(this.product?.description, [
        Validators.required,
      ]),
      images: new FormControl(this.product?.images, [Validators.required]),
      imagesToRemove: new FormControl(this.imagesToRemove),
      imagesToAdd: new FormControl(this.imagesToAdd),
    });
  }

  cancel() {
    return this.modalController.dismiss(this.editItemForm, 'cancel');
  }

  confirm() {
    return this.modalController.dismiss(this.editItemForm, 'confirm');
  }

  canDismiss() {
    if (
      this.editItemForm.get('price')?.hasError('required') ||
      this.editItemForm.get('name')?.hasError('required') ||
      this.editItemForm.get('category')?.hasError('required') ||
      this.editItemForm.get('description')?.hasError('required')
    ) {
      this.editItemForm.get('price')?.markAsTouched();
      this.editItemForm.get('name')?.markAsTouched();
      this.editItemForm.get('categoryId')?.markAsTouched();
      this.editItemForm.get('description')?.markAsTouched();
    } else {
      this.confirm();
    }
  }

  deleteImg(image: ProductImages) {
    this.updatedProduct.images = this.updatedProduct.images!.filter(
      (img) => img != image,
    );

    if (!this.imagesToRemove.find((removedImage) => removedImage == image)) {
      this.imagesToRemove.push(image);
    }
  }

  onFileSelected(event: any) {
    this.editItemForm.controls['imagesToAdd'] = event.target.files;
    this.imagesToAdd = event.target.files;

    if (this.imagesToAdd && this.imagesToAdd[0]) {
      const numberOfFiles = this.imagesToAdd.length;
      for (let i = 0; i < numberOfFiles; i++) {
        const reader = new FileReader();

        reader.onload = (e: any) => {
          this.imgUrls.push(e.target.result);
        };

        reader.readAsDataURL(this.imagesToAdd[i]);
      }
    }
  }

  deleteNewImg(image: File) {
    this.imgUrls = this.imgUrls.filter((img) => img != image);
  }
}
