<ion-content [fullscreen]="true">
  <ion-item>
    <ion-button
      color="secondary"
      slot="end"
      routerLink="/my-chats"
      class="my-chats-button"
    >
      <ion-icon name="mail-outline"></ion-icon>
    </ion-button>
  </ion-item>
  <ion-progress-bar *ngIf="loadingBar" type="indeterminate"></ion-progress-bar>
  <ion-list>
    <ion-card *ngFor="let post of posts">
      <ion-item>
        <div class="profile-image-container">
          <img [src]='post.createdBy?.userProfile?.profilePictureURL ?? defaultProfilePicURL'/>
        </div>
        <ion-label class="post-header">
          <h3 class="name">
            {{post.createdBy?.firstName}} {{post.createdBy?.lastName}}
          </h3>
          <p>{{post.createdDate | date:'short'}}</p>
        </ion-label>
      </ion-item>

      <ion-item>
        <ion-label>
          <h4>{{post.body}}</h4>

          @if (post.attachmentURL) { @if ( post.attachmentURL.includes(".mp4")
          || post.attachmentURL.includes(".mov") ||
          post.attachmentURL.includes(".wmv") ) {
          <video width="100%" height="240" controls controlsList="nodownload">
            <source [src]="post.attachmentURL" />
          </video>
          } @else {
          <img [src]="post.attachmentURL" />
          } }
        </ion-label>
        <ion-button
          (click)="setOptionsOpen(true, post.id, false)"
          *ngIf="post.createdBy?.id !== user?.userId"
          class="report-button"
          fill="clear"
          shape="round"
          slot="end"
        >
          <ion-icon name="ellipsis-horizontal-outline"></ion-icon>
        </ion-button>
        <ion-button
          *ngIf="post.createdBy?.id === user?.userId"
          (click)="deleteMyPost(post.id ?? '')"
          slot="end"
          class="report-button"
          fill="clear"
          shape="round"
        >
          <ion-icon name="trash"></ion-icon>
        </ion-button>
      </ion-item>
      <ion-item>
        <ion-button
          fill="clear"
          shape="round"
          size="small"
          (click)="newLike(post)"
          [disabled]="this.isUpdating"
        >
          <ion-icon
            slot="start"
            [name]=" post.likesData?.userLikedPost ? 'thumbs-up' : 'thumbs-up-outline' "
          ></ion-icon>
          <ion-label>
            <p>{{ post.likesData!.count}}</p>
          </ion-label>
        </ion-button>

        <ion-button
          fill="clear"
          shape="round"
          size="small"
          (click)="showCommentSection(post.id ?? '')"
        >
          <ion-icon slot="start" name="chatbox-outline"></ion-icon>
          <ion-label>
            <p>{{post.comments?.length ?? 0}}</p>
          </ion-label>
        </ion-button>
      </ion-item>

      <div
        *ngIf="isCommentSectionOpen && post.id === this.commentSectionPostId"
      >
        <ion-card *ngFor="let comment of post.comments">
          <ion-item>
            <div class="profile-image-container">
              <img [src]='post.createdBy?.userProfile?.profilePictureURL ?? defaultProfilePicURL'/>
            </div>
            
            <ion-label class="post-header">
              <h3 class="name">
                {{comment.createdBy?.firstName}} {{comment.createdBy?.lastName}}
              </h3>
              <p *ngIf="comment.createdDate">
                {{getCreateDate(comment.createdDate)}}
              </p>
            </ion-label>
          </ion-item>
          <ion-item>
            <ion-label>
              <h4>{{comment.body}}</h4>

              @if (comment.attachmentURL) { @if (
              comment.attachmentURL.includes(".mp4") ||
              comment.attachmentURL.includes(".mov") ||
              comment.attachmentURL.includes(".wmv") ) {
              <video
                width="100%"
                height="240"
                controls
                controlsList="nodownload"
              >
                <source [src]="comment.attachmentURL" />
              </video>
              } @else {
              <img [src]="comment.attachmentURL" />
              } }
            </ion-label>
            <ion-button
              *ngIf="comment.createdBy?.id !== user?.userId"
              class="report-button"
              fill="clear"
              shape="round"
              slot="end"
              (click)="setOptionsOpen(true, comment.id, true)"
            >
              <ion-icon name="ellipsis-horizontal-outline"></ion-icon>
            </ion-button>
            <ion-button
              *ngIf="comment.createdBy?.id === user?.userId"
              (click)="deleteMyComment(comment?.id)"
              slot="end"
              class="report-button"
              fill="clear"
              shape="round"
            >
              <ion-icon name="trash"></ion-icon>
            </ion-button>
          </ion-item>
        </ion-card>

        <ion-card>
          <ion-item>
            <ion-textarea
              label="Reply to {{post.createdBy?.firstName}} "
              labelPlacement="stacked"
              placeholder="Send a reply"
              [formControl]="postCommentText"
            ></ion-textarea>
            <ion-button fill="clear" (click)="createComment(post.id)">
              <ion-icon name="send-outline"></ion-icon>
            </ion-button>
          </ion-item>

          <ion-item *ngIf="commentImg" class="add-img">
            <img [src]="commentMediaURL" />
          </ion-item>

          <video
            *ngIf="commentVid"
            width="100%"
            height="240"
            controls
            controlsList="nodownload"
          >
            <source [src]="commentMediaURL" [type]="commentVid.mimeType" />
          </video>

          <ion-item>
            <input
              type="file"
              class="file-input"
              (change)="pickMedia(false, $event)"
              #fileUploadComment
            />
            <ion-button (click)="fileUploadComment.click()" fill="clear">
              <ion-icon name="image-outline"></ion-icon>
            </ion-button>

            <ion-button
              *ngIf="commentImg || commentVid"
              (click)="clearMedia(false)"
              fill="clear"
            >
              <ion-icon name="trash-outline"></ion-icon>
            </ion-button>
          </ion-item>
        </ion-card>
      </div>
    </ion-card>
  </ion-list>

  <ion-action-sheet
    [isOpen]="isOptionsSheetOpen"
    [buttons]="actionSheetButtons"
    (didDismiss)="setOptionsOpen(false)"
  ></ion-action-sheet>
</ion-content>

<ion-card>
  <ion-item>
    <form
      [formGroup]="createPostForm"
      style="width: 100%; display: flex; flex-direction: row"
    >
      <ion-textarea
        placeholder="What's happening today?"
        [autoGrow]="true"
        formControlName="body"
        class="mt-2"
      >
      </ion-textarea>

      <ion-button fill="clear" (click)="createPost()">
        <ion-icon name="send-outline"></ion-icon>
      </ion-button>
    </form>
  </ion-item>

  <ion-item *ngIf="postImg">
    <img [src]="postMediaURL" />
  </ion-item>

  <video *ngIf="postVid" controls controlsList="nodownload" class="ml-4">
    <source [src]="postMediaURL" [type]="postVid.mimeType" />
  </video>

  <ion-item>
    <input
      type="file"
      class="file-input"
      (change)="pickMedia(true, $event)"
      #fileUploadPost
    />
    <ion-button fill="clear" (click)="fileUploadPost.click()">
      <ion-icon name="image-outline"></ion-icon>
    </ion-button>

    <ion-button
      *ngIf="postImg || postVid"
      (click)="clearMedia(true)"
      fill="clear"
    >
      <ion-icon name="trash-outline"></ion-icon>
    </ion-button>
  </ion-item>
</ion-card>
