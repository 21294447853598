import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserProfile } from 'src/app/models/user-profile.model';
import {
  FormControl,
  FormGroup,
  FormsModule,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  business,
  at,
  home,
  school,
  bookmark,
  camera,
  schoolOutline,
} from 'ionicons/icons';
import {
  IonContent,
  IonTitle,
  IonList,
  IonItem,
  IonIcon,
  IonLabel,
  IonButton,
  IonInput,
  IonTextarea,
  IonText,
  IonToast,
  IonProgressBar,
  IonAlert,
  IonAvatar,
  IonSelect,
  IonSelectOption,
  IonImg,
} from '@ionic/angular/standalone';
import { addIcons } from 'ionicons';
import { Router } from '@angular/router';
import { MeService } from 'src/app/services/me.service';
import { ToastService } from 'src/app/services/toast.service';
import { FilePicker } from '@capawesome/capacitor-file-picker';
import { Subscription } from 'rxjs';
import { AppService } from 'src/app/services/app.service';
import { CampusService } from 'src/app/services/campus.service';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.page.html',
  styleUrls: ['./edit-profile.page.scss'],
  standalone: true,
  imports: [
    IonImg,
    IonAvatar,
    IonAlert,
    IonProgressBar,
    IonToast,
    IonText,
    IonTextarea,
    IonInput,
    IonButton,
    IonLabel,
    IonIcon,
    IonItem,
    IonList,
    IonContent,
    IonTitle,
    IonSelect,
    IonSelectOption,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
  ],
})
export class EditProfilePage {
  loading: boolean = false;

  userProfileData = {} as UserProfile;
  campuses: any = [];
  collegeName: string = '';
  campusName: string = '';
  email: string = '';
  street: string = '';
  city: string = '';
  state: string = '';
  zipcode: string = '';
  major: string = '';
  bio: string = '';

  profilePicture: string | undefined =
    'https://ionicframework.com/docs/img/demos/avatar.svg';

  editProfileForm: FormGroup = new FormGroup({
    campus: new FormControl(this.campusName, [Validators.required]),
    street: new FormControl(this.street),
    city: new FormControl(this.city, [Validators.required]),
    state: new FormControl(this.state, [Validators.required]),
    zipcode: new FormControl(this.zipcode, [Validators.required]),
    major: new FormControl(this.major, Validators.required),
    bio: new FormControl(this.bio, Validators.required),
  });

  backSubscription?: Subscription;

  constructor(
    private router: Router,
    private meService: MeService,
    private toastService: ToastService,
    private appService: AppService,
    private campusService: CampusService,
  ) {
    addIcons({ business, schoolOutline, at, home, school, bookmark, camera });
  }

  public alertButtons = [
    {
      text: 'Yes',
      role: 'cancel',
      handler: () => {
        this.router.navigate(['/my-profile']);
      },
    },
    {
      text: 'No',
      role: 'confirm',
    },
  ];

  ionViewWillEnter() {
    this.backSubscription = this.appService.backSubject.subscribe((_) => {
      this.router.navigateByUrl('/my-profile');
    });

    this.meService.getUserProfile().subscribe({
      next: (response) => {
        this.campusService
          .getCampusByCollegeId(response.campus!.collegeId)
          .subscribe({
            next: (response) => {
              this.campuses = response;
            },
          });

        this.userProfileData = response;
        this.collegeName = response.campus?.college.name ?? '';
        this.campusName = response.campus?.name ?? '';
        this.email = response.user.email;

        this.editProfileForm.setValue({
          campus: response.campus,
          street: response.street,
          city: response.city,
          state: response.state,
          zipcode: response.zipCode,
          major: response.major,
          bio: response.bio,
        });
      },
    });

    //GET user's profile picture
  }

  ionViewWillLeave(): void {
    if (this.backSubscription) {
      this.backSubscription.unsubscribe();
    }
  }

  async pickPhoto() {
    const result = await FilePicker.pickImages();

    this.profilePicture = result.files[0].path;
    this.profilePicture = 'temp'; // temporary value so undefined isnt stored here. Remove when testing on mobile starts
  }

  onSave(): void {
    this.loading = true;
    this.userProfileData.campus = this.editProfileForm.controls['campus'].value;
    this.userProfileData.campusId =
      this.editProfileForm.controls.campus.value.id;
    this.userProfileData.bio = this.editProfileForm.controls['bio'].value;
    this.userProfileData.major = this.editProfileForm.controls['major'].value;
    this.userProfileData.street = this.editProfileForm.controls['street'].value;
    this.userProfileData.city = this.editProfileForm.controls['city'].value;
    this.userProfileData.state = this.editProfileForm.controls['state'].value;
    this.userProfileData.zipCode =
      this.editProfileForm.controls['zipcode'].value;

    this.meService.updateUserProfile(this.userProfileData).subscribe();
    this.loading = false;
    this.toastService.presentToast(
      'Your profile information has been updated successfully.',
    );
    this.router.navigateByUrl('/my-profile');
  }
}
