import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Post } from '../models/post.model';
import { PickedFile } from '@capawesome/capacitor-file-picker';
import * as signalR from '@microsoft/signalr';
import { CustomHttpClient } from '../signalr/custom-http-client';
import { Logger } from '../signalr/custom-logger';
import { TokenService } from './token.service';
import { PatchDocument } from './dto.model';

@Injectable({
  providedIn: 'root',
})
export class PostService {
  path = '/api/Post';
  fileUploadPath = '/api/Storage/';
  postsSubject: Subject<{}> = new Subject();
  postDeletedSubject: Subject<{}> = new Subject();
  updatedLikesSubject: Subject<{ postId: string }> = new Subject();

  errorSubject: Subject<any> = new Subject();
  connection?: signalR.HubConnection;
  logger: signalR.ILogger;

  updateableProperties: string[] = ['likes'];

  constructor(
    private http: HttpClient,
    private tokenService: TokenService,
  ) {
    this.logger = new Logger();
  }

  getPosts(queryParams?: Map<string, any>): Observable<Post[]> {
    const queryParamString = this.buildQueryParameters(queryParams);
    return this.http.get<Post[]>(
      `${environment.API}${this.path}/${queryParamString}`,
    );
  }

  createPost(body: Post): Observable<Post> {
    return this.http.post<Post>(`${environment.API}${this.path}`, body);
  }

  deletePost(postId: string) {
    return this.http.delete(`${environment.API}${this.path}/${postId}`);
    return this.http.delete(`${environment.API}${this.path}/${postId}`);
  }

  updatePost(body: Post): Observable<Post> {
    const patchDoc = this.toPatchDoc(body);

    return this.http.patch<Post>(
      `${environment.API}${this.path}/${body.id}`,
      patchDoc,
      {
        headers: new HttpHeaders({
          Accept: 'application/json',
        }),
      },
    );
  }
  protected toPatchDoc(object: Post): PatchDocument[] {
    let doc: PatchDocument[] = [];

    if (this.updateableProperties) {
      for (const [key, value] of Object.entries(object)) {
        if (this.updateableProperties.includes(key)) {
          doc.push({
            op: 'replace',
            path: `/${key}`,
            value: value,
          });
        }
      }
    }

    return doc;
  }

  protected buildQueryParameters(queryParameters?: Map<string, any>): string {
    if (queryParameters == null || queryParameters.size == 0) {
      return '';
    }

    let queryString = '?';

    for (let entry of queryParameters.entries()) {
      if (typeof entry[1] == 'object') {
        for (let val of Array.from(entry[1])) {
          queryString += entry[0] + '=' + val + '&';
        }

        queryString = queryString.substring(0, queryString.length - 1);
      } else {
        queryString += entry[0] + '=' + queryParameters.get(entry[0]);
      }
    }

    return queryString;
  }

  uploadPostFile(postId: string, file: PickedFile) {
    var fileObj = new File([file.blob!], file.name);
    const formData = new FormData();

    formData.append(file.name, fileObj);
    formData.append('postId', postId);

    return this.http.post<Post>(
      `${environment.API}${this.fileUploadPath}Post`,
      formData,
    );
  }

  deletePostFile(postId: string) {
    return this.http.delete<Comment>(
      `${environment.API}${this.fileUploadPath}Post/${postId}`,
    );
  }

  report(id: string, reason: string) {
    return this.http.post(`${environment.API}${this.path}/Report`, {
      id,
      reason,
    });
    return this.http.post(`${environment.API}${this.path}/Report`, {
      id,
      reason,
    });
  }

  connect() {
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(`${environment.API}/bizibuzzhub`, {
        httpClient: new CustomHttpClient(
          this.logger,
          this.tokenService.authToken,
        ),
        accessTokenFactory: () => this.tokenService.authToken,
      })
      .build();

    this.connection.on('newPostReceived', () => {
      this.postsSubject.next({});
    });
    this.connection.on('postDeleted', () => {
      this.postDeletedSubject.next({});
    });
    this.connection.on('likesUpdated', (postId: string) => {
      this.updatedLikesSubject.next({ postId: postId });
    });

    this.connection.start().catch((err) => this.errorSubject.next(err));
  }

  disconnect() {
    if (this.connection) {
      this.connection.off('newPostReceived');
      this.connection.off('deletePost');
      this.connection.off('updateLikes');
      this.connection.stop();
    }
  }

  webSocketConnectionActions(action: string, postId?: string) {
    if (this.connection) {
      if (action == 'newPost') {
        this.connection.send('newPost').then(() => {});
      } else if (action == 'deletePost') {
        this.connection.send('deletePost').then(() => {});
      } else if (action == 'updateLikes') {
        this.connection.send('updateLikes', postId).then(() => {});
      }
    }
  }
}
