<ion-content [fullscreen]="true">
  <ion-card>
    <ion-card-header>
      <ion-card-title> Create an Account</ion-card-title>
      <ion-progress-bar type="indeterminate" *ngIf="loading"></ion-progress-bar>
      <ion-card-subtitle>
        Once your account is created, an email will be sent to your email
        address for verification.</ion-card-subtitle
      >
    </ion-card-header>

    <ion-card-content>
      <div [formGroup]="userInfoForm">
        <ion-item>
          <ion-input
            label="First Name"
            labelPlacement="floating"
            formControlName="firstName"
            maxlength="200"
          ></ion-input>
          <ion-text
            color="danger"
            *ngIf="userInfoForm.get('firstName')?.hasError('required') && userInfoForm.get('firstName')?.touched
          "
          >
            A first name is required.
          </ion-text>

          <ion-text
            color="danger"
            *ngIf="userInfoForm.get('firstName')?.hasError('whiteSpace')"
          >
            Must include more than white space.
          </ion-text>
        </ion-item>

        <ion-item>
          <ion-input
            label="Last Name"
            labelPlacement="floating"
            formControlName="lastName"
            maxlength="200"
          ></ion-input>
          <ion-text
            color="danger"
            *ngIf="userInfoForm.get('lastName')?.hasError('required') && userInfoForm.get('lastName')?.touched"
          >
            A last name is required.
          </ion-text>

          <ion-text
            color="danger"
            *ngIf="userInfoForm.get('lastName')?.hasError('whiteSpace') "
          >
            Must include more than white space.
          </ion-text>
        </ion-item>

        <ion-item>
          <ion-input
            label="Student Email"
            labelPlacement="floating"
            placeholder="lisaGorman@uoj.edu"
            formControlName="email"
            type="email"
            (ionInput)="findCampus($event)"
            [debounce]="500"
          ></ion-input>

          <ion-text
            color="danger"
            *ngIf="userInfoForm.get('email')?.hasError('required') && userInfoForm.get('email')?.touched"
          >
            A valid email is required.
          </ion-text>

          <ion-text
            color="danger"
            *ngIf="userInfoForm.get('email')?.hasError('whiteSpace')"
          >
            Must include more than white space.
          </ion-text>

          <ion-text
            color="danger"
            *ngIf="userInfoForm.get('email')?.hasError('noEduEmail')  || userInfoForm.get('email')?.hasError('email') 
      "
          >
            A valid edu email is required.
          </ion-text>
        </ion-item>

        <ion-item>
          <ion-input
            label="Password"
            labelPlacement="floating"
            formControlName="newPassword"
            type="password"
            maxlength="250"
          >
            <ion-input-password-toggle slot="end"></ion-input-password-toggle>
          </ion-input>

          <ion-text
            color="danger"
            *ngIf="userInfoForm.get('newPassword')?.hasError('required') && userInfoForm.get('newPassword')?.touched"
          >
            A password is required.
          </ion-text>

          <ion-text
            color="danger"
            *ngIf="userInfoForm.get('newPassword')?.hasError('whiteSpace')"
          >
            Must include more than white space.
          </ion-text>
        </ion-item>

        <ion-item>
          <ion-input
            label="Confirm Password"
            labelPlacement="floating"
            formControlName="confirmNewPassword"
            type="password"
            maxlength="250"
          >
            <ion-input-password-toggle slot="end"></ion-input-password-toggle>
          </ion-input>

          <ion-text
            color="danger"
            *ngIf="userInfoForm.get('confirmNewPassword')?.hasError('required') && userInfoForm.get('confirmNewPassword')?.touched"
          >
            Password confirmation is required.
          </ion-text>

          <ion-text
            color="danger"
            *ngIf="userInfoForm.get('confirmNewPassword')?.hasError('whiteSpace') "
          >
            Must include more than white space.
          </ion-text>
        </ion-item>

        <div class="flex">
          <ion-text
            color="danger"
            *ngIf=" userInfoForm.get('newPassword')?.touched && userInfoForm.get('confirmNewPassword')?.touched && userInfoForm.get('newPassword')?.hasError('passwordMatch') "
            class="ml-4"
          >
            Passwords must match.
          </ion-text>
        </div>

        <div class="flex">
          <ion-text
            color="danger"
            *ngIf="userInfoForm.get('newPassword')?.hasError('requirementsNotMet') && userInfoForm.get('newPassword')?.touched"
            class="ml-4"
          >
            Password must be atleast 8 characters and contain at one number, one
            uppercase letter, and one lowercase letter.
          </ion-text>
        </div>
      </div>

      <div [formGroup]="userProfileForm" class="mt-5">
        <ion-item>
          <ion-select
            [(ngModel)]="campusValue"
            label="Campus"
            formControlName="campusId"
            labelPlacement="floating"
          >
            <ion-select-option
              *ngFor="let campus of campuses"
              value="{{campus.id}}"
            >
              {{ campus.name }} {{campus.college.name}}
            </ion-select-option>
          </ion-select>
        </ion-item>

        <ion-item>
          <ion-input
            label="Phone Number"
            labelPlacement="floating"
            placeholder="111-111-1111"
            formControlName="phone"
            type="tel"
            [maskito]="phoneMask"
            [maskitoElement]="maskPredicate"
          ></ion-input>

          <ion-text
            color="danger"
            *ngIf="
      userProfileForm.get('phone')?.hasError('required') &&
      userProfileForm.get('phone')?.touched
    "
          >
            A phone number is required.
          </ion-text>

          <ion-text
            color="danger"
            *ngIf="userInfoForm.get('phone')?.hasError('whiteSpace') "
          >
            Must include more than white space.
          </ion-text>
        </ion-item>

        <ion-item>
          <ion-input
            label="Major"
            labelPlacement="floating"
            formControlName="major"
          ></ion-input>

          <ion-text
            color="danger"
            *ngIf="
    userProfileForm.get('major')?.hasError('required') &&
    userProfileForm.get('major')?.touched
  "
          >
            A major is required.
          </ion-text>

          <ion-text
            color="danger"
            *ngIf="userInfoForm.get('major')?.hasError('whiteSpace')"
          >
            Must include more than white space.
          </ion-text>
        </ion-item>
        <div class="flex justify-between">
          <ion-button (click)="resetForms() " fill="clear"> Clear</ion-button>
          <ion-button
            (click)="submitform()"
            [disabled]="userInfoForm.invalid || userProfileForm.invalid"
          >
            Create
          </ion-button>
        </div>
      </div>

      <ion-button routerLink="/login" fill="clear">
        Have an account? Log in
      </ion-button>
    </ion-card-content>
  </ion-card>
</ion-content>
