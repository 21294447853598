<ion-content>
  <div id="greeting">
    <div id="greeting-header">
      Hey <span id="greeting-username">{{username}}</span> !
    </div>
    <div id="greeting-sub">What are you looking for?</div>
  </div>

  <ion-grid class="flex justify-center">
    <ion-row>
      <ion-col class="flex justify-end">
        <ion-card
          class="home-grid-item ion-activatable ripple-parent"
          routerLink="/daily-chat"
        >
          <ion-text fill="clear" class="font-medium text-black">
            Bizi Buzz
          </ion-text>
          <ion-ripple-effect></ion-ripple-effect>
          <ion-icon name="chatbubble-ellipses-outline" size="large"></ion-icon>
        </ion-card>
      </ion-col>

      <ion-col class="flex justify-end">
        <ion-card
          routerLink="/shop-products"
          class="home-grid-item ion-activatable ripple-parent"
        >
          <ion-text fill="clear" class="font-medium text-black">
            Marketplace
          </ion-text>
          <ion-ripple-effect></ion-ripple-effect>
          <ion-icon name="bag-outline" size="large"></ion-icon>
        </ion-card>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col class="flex justify-start">
        <ion-card
          class="home-grid-item ion-activatable ripple-parent"
          routerLink="/local-deals"
        >
          <ion-text fill="clear" class="font-medium text-black">
            Local Deals
          </ion-text>
          <ion-ripple-effect></ion-ripple-effect>
          <ion-icon name="pricetag-outline" size="large"></ion-icon>
        </ion-card>
      </ion-col>

      <ion-col class="flex justify-start">
        <ion-card
          class="home-grid-item ion-activatable ripple-parent"
          routerLink="/my-profile"
        >
          <ion-text fill="clear" class="font-medium text-black">
            My Profile
          </ion-text>
          <ion-ripple-effect></ion-ripple-effect>
          <ion-icon name="person-outline" size="large"></ion-icon>
        </ion-card>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
