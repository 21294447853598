<ion-content>
  <ion-header>
    <ion-toolbar color="primary">
      <ion-buttons slot="start">
        <ion-button color="light" (click)="cancel()">Close</ion-button>
      </ion-buttons>

      <ion-title class="flex justify-center text-center">Create Chat</ion-title>

      <ion-buttons slot="end">
        <ion-button color="secondary" [strong]="true" (click)="save()"
          >Create</ion-button
        >
      </ion-buttons>
    </ion-toolbar>
  </ion-header>

  <div class="p-4">
    <ion-searchbar
      [debounce]="500"
      (ionInput)="handleSearch($event)"
      [(ngModel)]="searchValue"
    ></ion-searchbar>

    @if (availibleOptions().length > 0) {
      <ion-list>
        @for (user of availibleOptions(); track user.id) {
          <ion-item>
            <ion-label>{{ user.firstName }} {{ user.lastName }}</ion-label>

            <ion-button
              color="secondary"
              slot="end"
              (click)="userSelected(user)"
            >
              <ion-icon
                color="primary"
                size="large"
                name="add-outline"
                slot="icon-only"
              ></ion-icon>
            </ion-button>
          </ion-item>
        }
      </ion-list>
    } @else {
      <ion-text>
        @if (!searchValue || searchValue === "") {
          Start typing to search for other users to add.
        } @else {
          There are no users found with the given criteria.
        }
      </ion-text>
    }

    @if (selectedUsers().length > 0) {
      <h4 class="pt-2">Users Added to Chat</h4>

      <ion-list>
        @for (user of selectedUsers(); track user.id) {
          <ion-item>
            <ion-label>{{ user.firstName }} {{ user.lastName }}</ion-label>

            <ion-button
              color="secondary"
              slot="end"
              (click)="userRemoved(user)"
            >
              <ion-icon
                color="primary"
                size="large"
                name="trash-outline"
                slot="icon-only"
              ></ion-icon>
            </ion-button>
          </ion-item>
        }
      </ion-list>
    }
  </div>
</ion-content>
