import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ChangePassword } from '../models/change-password.model';
import { User } from '../models/user.model';
import { RegisterUser } from '../models/register-user.model';
import { UserVerification } from '../models/userVerification.model';
import { PatchDocument } from '../models/patch.model';
import { VerifyAccount } from '../models/verify-account.model';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  path = '/api/User';

  updatableProperties: string[] = ['status'];

  constructor(private http: HttpClient) {}

  create(body: RegisterUser) {
    return this.http.post(`${environment.API}${this.path}/${'Register'}`, body);
  }

  getUser(id: string): Observable<User> {
    return this.http.get<User>(`${environment.API}${this.path}/${id}`);
  }

  forgotPassword(email: string): Observable<any> {
    return this.http.post(
      `${environment.API}${this.path}/ForgotPassword`,
      { email: email },
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      },
    );
  }

  changePassword(changePassword: ChangePassword): Observable<any> {
    return this.http.post(`${environment.API}${this.path}/ChangePassword`, {
      newPassword: btoa(changePassword.newPassword),
      currentPassword: btoa(changePassword.currentPassword),
    });
  }

  updateUser(user: User) {
    const url = `${environment.API}${this.path}/${user.id}`;
    const patchDoc = this.toPatchDoc(user);

    return this.http.patch<User>(url, patchDoc, {
      headers: new HttpHeaders({
        Accept: 'application/json',
      }),
    });
  }

  search(term: string): Observable<User[]> {
    return this.http.post<User[]>(`${environment.API}${this.path}/Search`, {
      term,
    });
  }

  protected toPatchDoc(object: User): PatchDocument[] {
    let doc: PatchDocument[] = [];

    if (this.updatableProperties) {
      for (const [key, value] of Object.entries(object)) {
        if (this.updatableProperties.includes(key)) {
          doc.push({
            op: 'replace',
            path: `/${key}`,
            value: value,
          });
        }
      }
    }

    return doc;
  }

  checkAccountStatus(email: string) {
    return this.http.get<string>(`${environment.API}${this.path}/${email}`);
  }

  verifyAccount(verifyAccount: VerifyAccount) {
    return this.http.put<any>(
      `${environment.API}${this.path}/UpdateAccountStatus`,
      verifyAccount,
    );
  }
}
